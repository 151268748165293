import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import Loader from './landing/Loader'
import Carousel from './landing/Carousel'
import Process from './landing/Process'
import About from './landing/About'
import Category from './landing/Category'
import Navbar from './Navbar'
import Footer from './Footer'
// import CategorySliderWrap from "./landing/CategorySliderWrap";
import InstagramSection from './landing/InstagramSection'
import 'react-owl-carousel2/lib/styles.css'
import { getPublicDepartments } from '../../actions/departmentActions'

class Landing extends Component {
  state = {
    departments: []
  }

  componentDidMount () {
    // if (this.props.auth.isAuthenticated) {
    //   this.props.history.push("/dashboard");
    // }
    this.props.getPublicDepartments()
  }

  render () {
    return (
      <div>
        <Navbar />
        {/* <Header /> */}
        {/* <Loader /> */}
        <Carousel />
        <div className='container-fluid'>
          <About />
          <Process />
          <Category departments={this.props.departments} />
          {/* <CategorySliderWrap /> */}
        </div>
        <InstagramSection />
        <Footer />
        {/* </div> */}
      </div>
    )
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  getPublicDepartments: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  departments: state.departmentsData.departments
})
export default connect(
  mapStateToProps,
  { getPublicDepartments }
)(Landing)
