import React, { Component } from "react";
import ReactDOM from "react-dom";
import Spinner from "../common/Spinner";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { createUserTest, updateTime } from "../../actions/userTestActions";
import { getTest } from "../../actions/userTestActions";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";
import _ from "lodash";
import Sticky from 'react-sticky-el';

class Step2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      test: null,
      test_id: "",
      user: "",
      questions: [],
      _form: null,
      count: 0,
      totalQuestionsCount: 0,
      errors: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.test_id) {
      this.props.getTest(this.props.match.params.test_id);
    }
    this.attachNode(this.state._form);
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.test) {
      this.setState({ test: nextProps.test });
      this.setState({totalQuestionsCount: localStorage.getItem('totalQuestionsCount')});
      if(!localStorage.hasOwnProperty('testTime')){
        localStorage.setItem('testTime', nextProps.test.time)
      }
      this.setState({ count: localStorage.getItem('testTime') });
    }
    if (nextProps.userTestsData) {
      this.setState({ userTestsData: nextProps.userTestsData });
    }
  }

  attachNode(node) {
    this.state._form = ReactDOM.findDOMNode(node);
  }

  stopTimer() {
    // clearInterval(this.timer)
    if (this.state.count === 0 && this.state._form !== null) {
      // this.state._form.onSubmit()
      const { test } = this.state;
      let questionsData = _.uniqWith(this.state.questions, _.isEqual);
      questionsData = _.compact(questionsData);
      let newUserTest = {
        test: test._id,
        questions: questionsData,
        user: this.props.auth.user.id
      };
      let q = this.props.userTestsData.concat(questionsData);
      this.props.createUserTest(
        newUserTest,
        this.props.formEnd,
        q,
        this.props.history
      );
    }
  }

  tick() {
    this.setState({ count: this.state.count - 1 });
    if(this.state.count > 0) {
      this.props.updateTime(this.state.count);
      localStorage.setItem('testTime', this.state.count);
    }
    this.stopTimer();
  }

  startTimer() {
    setInterval(this.tick.bind(this), 60000);
  }

  handleChange = (e, questionId, refId) => {
    const questions = [...this.state.questions];
    let question = questions.find(q => q.question_id === refId);
    if (question) {
      question.answer = e.target.value;
      questions.push(question);
    } else {
      questions.push({ id: refId, answer: e.target.value });
    }
    this.setState({
      questions
    });
  };

  onSubmit(e) {
    e.preventDefault();
    const { test } = this.state;
    let questionsData = _.uniqWith(this.state.questions, _.isEqual);
    questionsData = _.compact(questionsData);
    let newUserTest = {
      test: test._id,
      questions: questionsData,
      user: this.props.auth.user.id
    };
    let q = this.props.userTestsData.concat(questionsData);
    this.props.createUserTest(
      newUserTest,
      this.props.formEnd,
      q,
      this.props.history
    );
    if (!this.props.formEnd) {
      this.props.jumpToStep(2);
    }
  }

  render() {
    console.log("step2");
    const { test, loading } = this.state;
    const questions = this.props.questions;
    let testItem;
    const that = this;
    if (test === null || loading) {
      testItem = <Spinner />;
    } else {
      testItem = (
        <div className="register">
          
          <div className="fluid-container">
            <div className="row">
              <div className="col-md-8">
                <h3 className="display-5 text-center">
                  Fill the correct options. Review before submitting
                </h3>
                <p className="lead text-center">
                  Time: {this.state.count} Minutes Left
                </p>
                <p className="lead text-center">
                  No of Questions: {this.state.totalQuestionsCount}
                </p>
                <hr></hr>
                <p className="text-center" />
                <form
                  noValidate
                  onSubmit={this.onSubmit}
                  ref={this.attachNode.bind(this)}
                >
                  {/* <input type='hidden' name='test_id' value={test._id} /> */}
                  {test &&
                    questions.map(function(object, idx) {
                      let questionId = `question-${idx}`;

                      let answerId = `answer-${idx}`;

                      let options = object.correctOptions;
                      let finalOptions = options.concat(object.wrongOptions);
                      return (
                        <div>
                          <p>
                            {idx + 1}. {object.statement}
                          </p>
                          <input
                            type="hidden"
                            name={questionId}
                            data-id={idx}
                            id={questionId}
                            value={object._id}
                            className="question_id"
                          />
                          {object.image !== null ? (
                            <img src={object.image} />
                          ) : (
                            ""
                          )}
                          <ul>
                            {finalOptions.map(function(correct, idxx) {
                              return (
                                <div key={idxx}>
                                  <li>
                                    <input
                                      type="radio"
                                      name={answerId}
                                      data-id={idx}
                                      id={answerId}
                                      value={correct.option}
                                      className="answer"
                                      onChange={e =>
                                        that.handleChange(
                                          e,
                                          questionId,
                                          object._id
                                        )
                                      }
                                    />
                                    &nbsp;
                                    {correct.option}
                                  </li>
                                </div>
                              );
                            })}
                            {/* {object.wrongOptions.map(function(wrong, i) {
                              return (
                                <div key={i}>
                                  <li>
                                    <input
                                      type="radio"
                                      name={answerId}
                                      data-id={idx}
                                      id={answerId}
                                      value={wrong.option}
                                      className="answer"
                                      onChange={e =>
                                        that.handleChange(
                                          e,
                                          questionId,
                                          object._id
                                        )
                                      }
                                    />
                                    &nbsp;
                                    {wrong.option}
                                  </li>
                                </div>
                              );
                            })} */}
                          </ul>
                        </div>
                      );
                    })}
                  <button type="submit" className="btn btn-info btn-block mt-4">
                    Save this Part
                  </button>
                </form>
              </div>
              <div className="col-md-offset-1 col-md-3 ml-20">
                <Sticky topOffset={80} stickyStyle= {{width: '100% !important'}}>
                  <p className="lead text-center">
                    Time: <strong>{this.state.count}</strong> Minutes Left
                  </p>
                  <p className="lead text-center">
                    No of Questions: <strong>{this.state.totalQuestionsCount}</strong>
                  </p>
                </Sticky>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="step step1">{testItem}</div>
      </div>
    );
  }
}

Step2.propTypes = {
  getTest: PropTypes.func.isRequired,
  test: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  test: state.testsData.test,
  auth: state.auth,
  userTestsData: state.userTestsData.questions
});

export default connect(
  mapStateToProps,
  { getTest, createUserTest, updateTime }
)(withRouter(Step2));
