import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { createJob } from "../../actions/jobActions";
import { getTests } from "../../actions/testActions";
import { getDepartments } from "../../actions/departmentActions";
import { getEducations } from "../../actions/educationActions";
import { getCompanies } from "../../actions/companyActions";
import { getEmploymentQuestions } from "../../actions/employmentQuestionsActions";
import { getJobs, getJob } from "../../actions/jobActions";

import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import Navbar from "../layout/Navbar";

import SelectListGroup from "../common/SelectListGroup";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { withAlert } from "react-alert";
import isEmpty from "../../validation/is-empty";

const locationOptionsArr = [
  {
    id: 1,
    label: "Nishat Chunian Limited Head Office",
    value: "Nishat Chunian Limited Head Office",
    toLat: 31.523551,
    toLng: 74.355500,
  },
  {
    id: 2,
    label: "Nishat Chunian Limited HTD SITE",
    value: "Nishat Chunian Limited HTD SITE",
    toLat: 31.248500,
    toLng: 74.170661,
  },
  {
    id: 3,
    label: "Nishat Chunian Spinning & Weaving SITE",
    value: "Nishat Chunian Spinning & Weaving SITE",
    toLat: 31.243925,
    toLng: 73.978562,
  },
];

class CreateJob extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      company: "",
      description: "",
      education: "",
      skills: [],
      cgpa: "",
      percentage: "",
      experience: "",
      career_level: "",
      job_type: "",
      end_date: moment().format(),
      start_date: moment().format(),
      test_link: "",
      status: "",
      test: "",
      department: "",
      tests: [],
      departments: [],
      companies: [],
      educations: [],
      employmentQuestions: [],
      location: "",
      conduct_test: false,
      disabled: true,
      copyJob: "",
      errors: {},
      selectedEducations: null,
      selectedEmploymentQuestions: null,
      toLat: "",
      toLng: "",
      locationOptions: locationOptionsArr,
    };
    this.onChange = this.onChange.bind(this);
    this.handleTextBoxChange = this.handleTextBoxChange.bind(this);
    this.handleSkillTextBoxChange = this.handleSkillTextBoxChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.handleStartChange = this.handleStartDateChange.bind(this);
    this.handleEndChange = this.handleEndDateChange.bind(this);
    this.copyFrom = this.copyFrom.bind(this);
  }

  onCheck(e) {
    this.setState({
      disabled: !this.state.disabled,
      conduct_test: !this.state.conduct_test,
    });
  }

  handleChange = (selectedEducations) => {
    this.setState({ selectedEducations });
    console.log(`Option selected:`, selectedEducations);
  };

  handleEqChange = (selectedEmploymentQuestions) => {
    this.setState({ selectedEmploymentQuestions });
    console.log(`Option selected:`, selectedEmploymentQuestions);
  };

  copyFrom(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.getJob(e.target.value);
  }
  onSubmit(e) {
    e.preventDefault();
    // Create array of educations for Job.
    let educationsArray = [];
    if (this.state.selectedEducations !== null) {
      educationsArray = this.state.selectedEducations.map((ed) => {
        return {
          _id: ed.value,
        };
      });
    }
    let eqArray = [];
    if (this.state.selectedEmploymentQuestions !== null) {
      eqArray = this.state.selectedEmploymentQuestions.map((eq) => {
        return {
          _id: eq.value,
        };
      });
    }
    let newJob = {
      title: this.state.title,
      company: this.state.company,
      description: this.state.description,
      education: this.state.education,
      skills: this.state.skills,
      cgpa: this.state.cgpa,
      percentage: this.state.percentage,
      experience: this.state.experience,
      career_level: this.state.career_level,
      job_type: this.state.job_type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      department: this.state.department,
      location: this.state.location,
      toLat: this.state.toLat,
      toLng: this.state.toLng,
      conduct_test: this.state.conduct_test,
      educations: educationsArray,
      employment_questions: eqArray,
    };
    console.log("aaa", newJob);
    if (this.state.test !== "") {
      newJob.test = this.state.test;
    }
    this.props.createJob(newJob, this.props.history, this.props.alert);
  }

  componentDidMount() {
    this.props.getTests();
    this.props.getDepartments();
    this.props.getEducations();
    this.props.getEmploymentQuestions();
    this.props.getCompanies();
    this.props.getJobs();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.tests) {
      this.setState({ tests: nextProps.tests });
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments });
    }
    if (nextProps.educations) {
      this.setState({ educations: nextProps.educations });
    }
    if (nextProps.employmentQuestions) {
      this.setState({ employmentQuestions: nextProps.employmentQuestions });
    }
    if (nextProps.companies) {
      this.setState({ companies: nextProps.companies });
    }
    if (!isEmpty(nextProps.job) && !isEmpty(this.state.copyJob)) {
      const job = nextProps.job;
      this.setState({
        title: job.title,
        company: job.company && job.company._id,
        description: job.description,
        education: job.education,
        skills: job.skills.join(","),
        cgpa: job.cgpa.toString(),
        percentage: job.percentage && job.percentage.toString(),
        experience: job.experience.toString(),
        career_level: job.career_level,
        job_type: job.job_type,
        start_date: job.start_date,
        end_date: job.end_date,
        department: job.department,
        // location: job.location,
        test: job.test,
        conduct_test: job.conduct_test,
        selectedEducations:
          job.educations &&
          job.educations.map((ed) => {
            return {
              label: ed.title,
              value: ed._id,
            };
          }),
        selectedEmploymentQuestions:
          job.employment_questions &&
          job.employment_questions.map((eq) => {
            return {
              label: eq.title,
              value: eq._id,
            };
          }),
      });
      this.onCheck();
    }
  }

  onChange(e) {
    const { locationOptions } = this.state;
    let tests = this.props.tests;

    if (e.target.name == "location") {
      /////////////////////////////////////////////////////////////
      const selectedValue = e.target.value;

      // Find the selected location option
      const selectedOption = locationOptions.find(
        (option) => option.value === selectedValue
      );

      if (selectedOption) {
        this.setState({
          location: e.target.value,
          toLat: selectedOption.toLat,
          toLng: selectedOption.toLng,
        });
      }
      /////////////////////////////////////////////////////////////
    } else {
      if (e.target.name == "department") {
        tests = tests.filter((t) => t.department == e.target.value);
      }
      if (e.target.name == "career_level") {
        tests = tests.filter((t) => t.level == e.target.value.toLowerCase());
      }
      if (e.target.name == "cgpa") {
        if (e.target.value < 1 || e.target.value > 5)
          this.setState({ errors: { cgpa: "CGPA must be between 1 to 5." } });
        else {
          this.setState({ errors: { cgpa: false } });
        }
      }
      console.log("llllll>>>>>", e.target.name, e.target.value);
      this.setState({ [e.target.name]: e.target.value, tests: tests });
    }
  }
  handleStartDateChange(date) {
    this.setState({
      start_date: date,
    });
  }
  handleEndDateChange(date) {
    this.setState({
      end_date: date,
    });
  }

  handleTextBoxChange(value) {
    this.setState({ description: value });
  }
  handleSkillTextBoxChange(value) {
    this.setState({ skills: value });
  }

  render() {
    const { errors } = this.state;

    const { tests, companies } = this.state;

    const { departments, locationOptions } = this.state;

    const { educations } = this.state;

    const { jobs } = this.props;
    const jobTitles = jobs.map((j) => j.title);

    const { employmentQuestions } = this.state;

    const formattedEducations = educations.map((ed) => {
      return {
        label: ed.title,
        value: ed._id,
      };
    });

    const formattedEmploymentQuestions = employmentQuestions.map((eq) => {
      return {
        label: eq.title,
        value: eq._id,
      };
    });

    const copyFromOptions = jobs.map((j) => {
      return {
        label: j.title,
        value: j._id,
      };
    });

    const formattedCompanies = companies.map((eq) => {
      return {
        label: eq.title,
        value: eq._id,
      };
    });

    const deptOptions = [];
    for (var i = 0; i < departments.length; i++) {
      deptOptions.push({
        label: departments[i].title,
        value: departments[i]._id,
      });
    }

    const testOptions = [];
    for (var i = 0; i < tests.length; i++) {
      testOptions.push({
        label: tests[i].title,
        value: tests[i]._id,
      });
    }
    return (
      <div>
        <Navbar />
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">New Job</h1>
                <p className="lead text-center">Create New Job</p>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <select
                      className="form-control form-control-lg"
                      onChange={this.copyFrom}
                      name="copyJob"
                      value={this.state.copyJob}
                    >
                      <option>
                        select from the dropdown if you want to copy from a
                        previous job posting
                      </option>
                      {jobs &&
                        jobs.map((j) => {
                          return <option value={j._id}>{j.title}</option>;
                        })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.title,
                      })}
                      placeholder="Title"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className="invalid-feedback">{errors.title}</div>
                    )}
                  </div>

                  <SelectListGroup
                    placeholder="Department"
                    name="department"
                    value={this.state.department}
                    onChange={this.onChange}
                    options={deptOptions}
                    error={errors.department}
                    info="Select the department for this Job"
                  />

                  <SelectListGroup
                    placeholder="Company"
                    name="company"
                    value={this.state.company}
                    onChange={this.onChange}
                    options={formattedCompanies}
                    error={errors.company}
                    info="Select the company for this Job"
                  />
                  <label>Job Description</label>

                  <ReactQuill
                    value={this.state.description}
                    onChange={this.handleTextBoxChange}
                  />
                  {errors.description && (
                    <p className="description-error">{errors.description} </p>
                  )}
                  <div className="form-group">
                    <label>Skills</label>
                    {/* <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.skills,
                      })}
                      placeholder="Skills"
                      name="skills"
                      value={this.state.skills}
                      onChange={this.onChange}
                    /> */}
                    <ReactQuill
                      value={this.state.skills}
                      onChange={this.handleSkillTextBoxChange}
                    />
                    {errors.skills && (
                      <div className="invalid-feedback">{errors.skills}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>CGPA</label>
                    <input
                      type="number"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.cgpa,
                      })}
                      value={this.state.cgpa}
                      onChange={this.onChange}
                      name="cgpa"
                      min={1}
                      max={5}
                      placeholder="CGPA"
                    />
                    {errors.cgpa && (
                      <div className="invalid-feedback">{errors.cgpa}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Percentage</label>
                    <input
                      type="number"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.percentage,
                      })}
                      placeholder="Percentage"
                      name="percentage"
                      value={this.state.percentage}
                      onChange={this.onChange}
                    />
                    {errors.percentage && (
                      <div className="invalid-feedback">
                        {errors.percentage}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Experience</label>
                    <input
                      type="number"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.experience,
                      })}
                      placeholder="Experience"
                      name="experience"
                      value={this.state.experience}
                      onChange={this.onChange}
                    />
                    {errors.experience && (
                      <div className="invalid-feedback">
                        {errors.experience}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Add Educational Qualifications for this job</label>
                    <Select
                      value={this.state.selectedEducations}
                      onChange={this.handleChange}
                      options={formattedEducations}
                      isMulti
                    />
                  </div>
                  <div className="form-group">
                    <label>Select Employment Questions for this job</label>
                    <Select
                      value={this.state.selectedEmploymentQuestions}
                      onChange={this.handleEqChange}
                      options={formattedEmploymentQuestions}
                      isMulti
                    />
                  </div>
                  <div className="form-group">
                    <label>Career Level</label>
                    <select
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.career_level,
                      })}
                      placeholder="Career Level"
                      name="career_level"
                      value={this.state.career_level}
                      onChange={this.onChange}
                    >
                      <option value="" disabled>
                        Select career level
                      </option>
                      <option> Entry</option>
                      <option> Mid</option>
                      <option> Advanced</option>
                    </select>
                    {/* <input type="text" /> */}
                    {errors.career_level && (
                      <div className="invalid-feedback">
                        {errors.career_level}
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Job Type</label>
                    <select
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.job_type,
                      })}
                      placeholder="Job Type"
                      name="job_type"
                      value={this.state.job_type}
                      onChange={this.onChange}
                    >
                      <option value="" disabled>
                        Select Job type
                      </option>
                      <option> Permanent</option>
                      <option> Contractual</option>
                    </select>
                    {/* <input type="text" /> */}
                    {errors.job_type && (
                      <div className="invalid-feedback">{errors.job_type}</div>
                    )}
                  </div>

                  <div className="form-group">
                    {/* <label>Validity Date</label> */}
                    <label>Start Date</label>
                    <br />
                    <DatePicker
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.start_date,
                      })}
                      selected={moment(this.state.start_date)}
                      onChange={this.handleStartChange}
                    />
                    {errors.end_date && (
                      <div className="invalid-feedback">{errors.end_date}</div>
                    )}
                  </div>
                  <div className="form-group">
                    {/* <label>Validity Date</label> */}
                    <label>End Date</label>
                    <br />
                    <DatePicker
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.end_date,
                      })}
                      selected={moment(this.state.end_date)}
                      onChange={this.handleEndChange}
                    />
                    {errors.end_date && (
                      <div className="invalid-feedback">{errors.end_date}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Location</label>
                    {/* <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.job_type
                      })}
                      placeholder='Location'
                      name='location'
                      value={this.state.location}
                      onChange={this.onChange}
                    /> */}
                    <SelectListGroup
                      placeholder="location"
                      name="location"
                      value={this.state.location}
                      onChange={this.onChange}
                      options={locationOptions}
                      error={errors.location}
                      // disabled={this.state.disabled}
                      info="Select the location for this Job"
                    />
                    {errors.location && (
                      <div className="invalid-feedback">{errors.location}</div>
                    )}
                  </div>
                  <div className="form-check mb-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="current"
                      value={this.state.conduct_test}
                      checked={this.state.conduct_test}
                      onChange={this.onCheck}
                      id="conduct_test"
                    />
                    <label htmlFor="conduct_test" className="form-check-label">
                      Conduct Test ?
                    </label>
                  </div>
                  <SelectListGroup
                    placeholder="Test"
                    name="test"
                    value={this.state.test}
                    onChange={this.onChange}
                    options={testOptions}
                    error={errors.test}
                    disabled={this.state.disabled}
                    info="Select the test for this Job"
                  />
                  <button type="submit" className="btn btn-info btn-block mt-4">
                    Post this Job
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateJob.propTypes = {
  createJob: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getTests: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  getDepartments: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  getEducations: PropTypes.func.isRequired,
  educations: PropTypes.array.isRequired,
  getEmploymentQuestions: PropTypes.func.isRequired,
  employmentQuestions: PropTypes.array.isRequired,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  jobs: PropTypes.array.isRequired,
  job: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  tests: state.testsData.tests,
  departments: state.departmentsData.departments,
  educations: state.educationsData.educations,
  employmentQuestions: state.employmentQuestionsData.employmentQuestions,
  companies: state.companiesData.companies,
  jobs: state.jobsData.jobs,
  job: state.jobsData.job,
});

export default connect(mapStateToProps, {
  createJob,
  getTests,
  getDepartments,
  getEducations,
  getEmploymentQuestions,
  getCompanies,
  getJobs,
  getJob,
})(withAlert(CreateJob));