import {
  GET_TESTS,
  GET_TEST,
  DELETE_TEST,
  UPDATE_TEST,
  TEST_LOADING,
  GET_FLASH
} from '../actions/types'

const initialState = {
  test: {},
  tests: [],
  loading: false,
  flash: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEST:
      return {
        ...state,
        test: action.payload
      }
    case GET_TESTS:
      return {
        ...state,
        tests: action.payload
      }
    case GET_FLASH: {
      return {
        ...state,
        flash: 'Test saved Successfully!'
      }
    }
    case UPDATE_TEST:
      return {
        ...state,
        test: action.payload
      }
    case DELETE_TEST:
      return {
        ...state,
        // remove deleted job from state to update page
        tests: state.tests.filter(test => test._id !== action.payload.id),
        loading: false
      }
    case TEST_LOADING:
      return {
        ...state,
        loading: true
      }
    default:
      return state
  }
}
