import axios from 'axios'

import {
  GET_ERRORS,
  GET_COMPANY,
  GET_COMPANIES,
  DELETE_COMPANY,
  COMPANY_LOADING
} from './types'

// Create a Company
export const createCompany = (companiesData, history) => dispatch => {
  axios
    .post('/api/admin/companies/create', companiesData)
    .then(res => history.push('/companies'))
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Update New Department
export const updateCompany = (id, companyData, history) => dispatch => {
  axios
    .post('/api/admin/companies/update/' + id, companyData)
    .then(res => history.push('/companies'))
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Delete Company
export const deleteCompany = id => dispatch => {
  dispatch(setCompanyLoading())
  axios
    .delete(`/api/admin/companies/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_COMPANY,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    )
}

export const getCompany = id => dispatch => {
  axios
    .get('/api/admin/companies/' + id)
    .then(res => {
      dispatch({
        type: GET_COMPANY,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Get all Companies
export const getCompanies = () => dispatch => {
  dispatch(setCompanyLoading())
  axios
    .get('/api/admin/companies')
    .then(res =>
      dispatch({
        type: GET_COMPANIES,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    })
}

// Set Loader icon untill state changes
export const setCompanyLoading = () => {
  return {
    type: COMPANY_LOADING
  }
}
