import {
  GET_JOBS,
  GET_JOB,
  DELETE_JOB,
  APPLY_FOR_JOB,
  SHORTLIST_APPLICANT,
  REMOVE_APPLICANT,
  CHANGE_JOB_STATUS,
  GET_USER_JOB,
  GET_USER_JOBS,
  SET_JOB_APPLYING,
  REMOVE_JOB_APPLYING,
  GET_ALL_USER_JOBS,
  GET_FLASH
} from '../actions/types'

const initialState = {
  job: {},
  jobs: [],
  loading: false,
  flash: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOBS:
      return {
        ...state,
        jobs: action.payload,
        loading: false
      }
    case GET_JOB: {
      return {
        ...state,
        job: action.payload.job,
        applicants: action.payload.applicants,
        loading: false
      }
    }
    case APPLY_FOR_JOB:
      return {
        ...state,
        job: action.payload,
        loading: false
      }
    case SHORTLIST_APPLICANT: {
      return {
        ...state,
        job: action.payload
      }
    }
    case GET_FLASH: {
      return {
        ...state,
        flash: 'Job saved Successfully!'
      }
    }
    case DELETE_JOB:
      return {
        ...state,
        // remove deleted job from state to update page
        jobs: state.jobs.filter(job => job._id !== action.payload.id),
        loading: false
      }
    case REMOVE_APPLICANT:
      return {
        ...state,
        applicants: action.payload
      }
    case CHANGE_JOB_STATUS: {
      return {
        ...state,
        job: action.payload
      }
    }
    case GET_USER_JOB: {
      return {
        ...state,
        userJob: action.payload
      }
    }
    // following get data from UserJob model
    case GET_USER_JOBS: {
      return {
        ...state,
        userJobs: action.payload
      }
    }
    case SET_JOB_APPLYING: {
      return {
        ...state,
        jobApplying: action.payload
      }
    }
    case REMOVE_JOB_APPLYING: {
      return {
        ...state,
        jobApplying: action.payload
      }
    }
    case GET_ALL_USER_JOBS: {
      return {
        ...state,
        allUserJobs: action.payload
      }
    }

    default:
      return state
  }
}
