import React, { Component } from "react";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getDepartments,
  deleteDepartment
} from "../../actions/departmentActions";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Navbar from "../layout/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class Departments extends Component {
  // following is ES6 syntax to initialze constructor
  state = {
    departments: null
  };

  onDeleteClick(id) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.deleteDepartment(id)
        },
        {
          label: "No",
          onClick: () => ""
        }
      ]
    });
  }

  componentDidMount() {
    this.props.getDepartments();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments });
    }
  }

  render() {
    const { loading } = this.props;

    const { departments } = this.state;

    const columns = [
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: () => <span>Actions</span>,
        accessor: "_id",
        Cell: row => (
          <div>
            <i
              className="fa fa-trash-alt"
              aria-hidden="true"
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={"/departments/edit/" + row.value}>
              <i className="far fa-edit" title="Edit" />
              {/* <button className='btn btn-warning'>Edit</button> */}
            </Link>
          </div>
        )
      }
    ];

    let departmentsListing;
    let data;
    if (departments == null || loading) {
      departmentsListing = <Spinner />;
    } else {
      if (departments.length > 0) {
        data = departments;
      } else {
        departmentsListing = <h4> No Departments found...</h4>;
      }
    }

    return (
      <div>
        <Navbar />
        <div className="profiles">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="new-job">
                  <Link to={`/departments/new`} className="btn btn-info">
                    Create a Department
                  </Link>
                </div>
                <h3 className="display-5 text-center">Departments Listing</h3>
                <p className="lead text-center">Browse Departments</p>
                <ReactTable data={data} columns={columns} filterable />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Departments.propTypes = {
  getDepartments: PropTypes.func.isRequired,
  deleteDepartment: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  departments: state.departmentsData.departments
});

export default connect(
  mapStateToProps,
  {
    getDepartments,
    deleteDepartment
  }
)(Departments);
