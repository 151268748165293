import { GET_COMPANIES, GET_COMPANY, DELETE_COMPANY } from "../actions/types";

const initialState = {
  company: "",
  companies: [],
  loading: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
        loading: false
      };
    case DELETE_COMPANY:
      return {
        ...state,
        // remove deleted Company from state to update page
        companies: state.companies.filter(
          company => company._id !== action.payload.id
        ),
        loading: false
      };
    default:
      return state;
  }
}
