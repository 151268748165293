import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUserJobs } from '../../actions/jobActions'
import { logoutUser } from '../../actions/authActions'
import NavLinks from './NavLinks'
import { getAllUserTests } from '../../actions/userTestActions'

class Navbar extends Component {
  componentDidMount () {
    if (this.props.auth.user.role == 'user') {
      this.props.getUserJobs(this.props.auth.user.id)
      this.props.getAllUserTests()
    }
  }

  onLogoutClick (e) {
    e.preventDefault()
    this.props.logoutUser()
  }

  render () {
    const { isAuthenticated, user } = this.props.auth
    const { userJobs, allUserTests } = this.props
    const userTestsIds =
      allUserTests &&
      allUserTests
        .filter(aut => aut.user == user.id)
        .filter(aut => !aut.score)
        .map(aut => {
          return aut.test
        })
    const guestLinks = (
      <ul className='navbar-nav ml-auto'>
        <li>
          <Link className='nav-link' to='/register'>
            Sign Up
          </Link>
        </li>
        <li>
          <Link className='nav-link' to='/login'>
            Login
          </Link>
        </li>
      </ul>
    )

    const jobsLink = (
      <li>
        <Link className='nav-link' to='/jobs'>
          Jobs
        </Link>
      </li>
    )
    const authLinks = (
      <div>
        {!(user.role === 'admin') && (
          <ul className='navbar-nav ml-auto'>
            <li>
              <Link to=''>Logged in as {user.firstName}</Link>
            </li>
            <li>
              <Link to='/profile-step-5' className='nav-link'>
                {/* <i className="fa fa-user fa-lg" /> */}
                Profile
              </Link>
            </li>
            <li>
              <span className='nav-link1'>
                {userJobs &&
                  userJobs.map(uj => {
                    if (uj.testLink && uj.testTaken !== true) {
                      return (
                        <Link to={`${uj.testLink}/instructions`}>
                          Test Invite
                        </Link>
                      )
                    }
                  })}
              </span>
            </li>

            <li>
              <a
                href='/'
                onClick={this.onLogoutClick.bind(this)}
                className='nav-link'
              >
                Logout
              </a>
            </li>
          </ul>
        )}
        {user.role === 'admin' && (
          <a
            href='/'
            onClick={this.onLogoutClick.bind(this)}
            className='nav-link'
          >
            <i className='fas fa-sign-out-alt fa-lg' />
          </a>
        )}
      </div>
    )

    const adminLinks = (
      <nav
        className='navbar navbar-expand-sm navbar-dark mb-4'
        style={{ borderBottom: '1px solid #eee' }}
      >
        <div className='container'>
          <div className='siteLogo'>
            <a className='navbar-brand' href='/'>
              <img src={require('../../images/site-logo.svg')} alt='Logo' />
            </a>
          </div>
          {/* <Link className='navbar-brand' to='/'>
            Nishat HR Recruitment Portal
          </Link> */}
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#mobile-nav'
          >
            <span className='navbar-toggler-icon' />
          </button>

          <div className='collapse navbar-collapse' id='mobile-nav'>
            <nav className='mainNav'>
              <ul className='navbar-nav mr-auto'>
                {/* {isAuthenticated ? jobsLink : ''} */}

                <li>
                  <Link to='/jobs'>{isAuthenticated ? 'Jobs' : null}</Link>
                </li>

                <li>
                  <Link to='/tests'>{isAuthenticated ? 'Tests' : ''}</Link>
                </li>
                <li>
                  <Link to='/companies'>
                    {isAuthenticated ? 'Companies' : ''}
                  </Link>
                </li>
                <li>
                  <Link to='/employment_questions'>
                    {isAuthenticated ? 'Employment Questions' : ''}
                  </Link>
                </li>
                <li>
                  <Link to='/educations'>
                    {isAuthenticated ? 'Add Degrees' : ''}
                  </Link>
                </li>
                <li>
                  <Link to='/departments'>
                    {isAuthenticated ? 'Departments' : ''}
                  </Link>
                </li>
                <li>
                  <Link to='/change_password'>
                    {isAuthenticated ? 'Change Password' : ''}
                  </Link>
                </li>
              </ul>
            </nav>
            {isAuthenticated ? authLinks : guestLinks}
          </div>
        </div>
      </nav>
    )

    const userLinks = (
      <header className='siteHeader'>
        <div className='headerContainer container-fluid clearfix'>
          <div className='siteLogo'>
            <a href='/'>
              <img src={require('../../images/site-logo.svg')} alt='Logo' />
            </a>
          </div>

          <div className='userDropdown'>
            <button
              className='dropdown-toggle'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span>
                <i className='far fa-user-circle' />
              </span>
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              {isAuthenticated ? authLinks : guestLinks}
            </div>
          </div>

          <nav className='mainNav'>
            <NavLinks />
          </nav>
        </div>
      </header>
    )

    const header = user.role === 'admin' ? adminLinks : userLinks
    return <div>{header}</div>
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  getUserJobs: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  userJobs: PropTypes.object,
  getAllUserTests: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  userJobs: state.jobsData.userJobs,
  allUserTests: state.userTestsData.allUserTests
})

export default connect(
  mapStateToProps,
  { logoutUser, getUserJobs, getAllUserTests }
)(Navbar)
