import React, { Component } from 'react'
import Spinner from '../common/Spinner'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCompanies, deleteCompany } from '../../actions/companyActions'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import Navbar from '../layout/Navbar'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
class Companies extends Component {
  // follwoeing is ES6 syntax to initialze constructor
  state = {
    companies: null
  }

  onDeleteClick (id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteCompany(id)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    })
  }

  componentDidMount () {
    this.props.getCompanies()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.companies) {
      this.setState({ companies: nextProps.companies })
    }
  }

  render () {
    const { loading } = this.props

    const { companies } = this.state

    const columns = [
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: () => <span>Actions</span>,
        accessor: '_id',
        Cell: row => (
          <div>
            <i
              className='fa fa-trash-alt'
              aria-hidden='true'
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={'/companies/edit/' + row.value}>
              <i className='far fa-edit' title='Edit' />
              {/* <button className='btn btn-warning'>Edit</button> */}
            </Link>
          </div>
        )
      }
    ]

    let companiesListing
    let data
    if (companies == null || loading) {
      companiesListing = <Spinner />
    } else {
      if (companies.length > 0) {
        data = companies
      } else {
        companiesListing = <h4> No Departments found...</h4>
      }
    }

    return (
      <div>
        <Navbar />
        <div className='container'>
          <div className='profiles'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='new-job'>
                    <Link to={`/companies/new`} className='btn btn-info'>
                      Create a Company
                    </Link>
                  </div>
                  <h3 className='display-5 text-center'>Companies Listing</h3>
                  <p className='lead text-center'>Browse Companies</p>
                  <ReactTable data={data} columns={columns} filterable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Companies.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  companies: state.companiesData.companies
})

export default connect(
  mapStateToProps,
  {
    getCompanies,
    deleteCompany
  }
)(Companies)
