import { removeJobApplying } from '../../actions/jobActions'
import isEmpty from '../../validation/is-empty'
import React, { Component } from 'react'
import SignupHeader from './ProfileHeader'
import SiteLogo from '../common/SiteLogo'
import ProfileFooter from './ProfileFooter'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUser } from '../../actions/authActions'
import PropTypes from 'prop-types'
import { createProfileStepFive } from '../../actions/authActions'
import isValid from '../../validation/profileValidation'
import 'react-notifications-component/dist/theme.css'
import { withAlert } from 'react-alert'

class ProfileStepFive extends Component {
  constructor () {
    super()
    this.state = {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      city: '',
      address: '',
      phoneNumber: '',
      avatar: '',
      resume: '',
      resumeFileName: ''
    }
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    if (this.props.user.id) {
      this.props.getUser(this.props.user.id)
    }
    if (!isEmpty(this.props.jobApplying)) {
      this.props.alert.show(`Please Review your profile before applying.`)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.profile) {
      if (!isValid(nextProps.profile, '5')) {
        // this.props.history.push("/profile-step-4");
      }
    }
    if (nextProps.profile) {
      this.setState({
        firstName: nextProps.profile.firstName,
        middleName: nextProps.profile.middleName,
        lastName: nextProps.profile.lastName,
        email: nextProps.profile.email,
        phoneNumber: nextProps.profile.phoneNumber,
        city: nextProps.profile.city,
        address: nextProps.profile.address,
        avatar: nextProps.profile.avatar,
        resume: nextProps.profile.resume,
        resumeFileName: nextProps.profile.resumeFileName
      })
    }
  }

  onSubmit (e) {
    e.preventDefault()
    if (!isEmpty(this.props.jobApplying)) {
      this.props.history.push(
        `/jobs/apply/${this.props.jobApplying}/employmentQuestions`
      )
      this.props.removeJobApplying()
    } else if (!isEmpty(this.props.profile.firstJobId)) {
      this.props.history.push(
        `/jobs/apply/${this.props.profile.firstJobId}/employmentQuestions`
      )
    } else {
      this.props.createProfileStepFive(this.props)
    }
  }

  addNotification (title, type, message) {
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: message,
      type: type,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    })
  }

  render () {
    const { career, education, employmentQuestions } = this.props.profile
    return (
      <div>
        <SignupHeader tab={4} />
        <div className='signupStepSection'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-md-10'>
                {/* <SiteLogo /> */}
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='loginForm'>
                    <div className='reviewSectionTop'>
                      <h3> Personal Information / CV Upload </h3>
                      <Link className='editOnReview' to='/profile-step-1'>
                        <i className='far fa-edit' title='Edit' />
                      </Link>
                    </div>
                    <ul className='signupReview'>
                      <li className='clearfix'>
                        <span> Your Picture </span>

                        <div className='profilePicView'>
                          <img
                            src={
                              this.state.avatar ||
                              require('../../images/default.png')
                            }
                            alt='img'
                          />
                        </div>
                      </li>
                      <li>
                        <span> Your Name </span>
                        <strong>
                          {this.state.firstName} {this.state.middleName}{' '}
                          {this.state.lastName}{' '}
                        </strong>
                      </li>
                      <li>
                        <span> Email </span>
                        <strong> {this.state.email} </strong>
                      </li>
                      <li>
                        <span> Phone </span>
                        <strong> {this.state.phoneNumber} </strong>
                      </li>
                      <li>
                        <span> Address </span>
                        <strong> {this.state.address} </strong>
                      </li>
                      <li>
                        <span> City </span>
                        <strong> {this.state.city} </strong>
                      </li>
                      <li>
                        <span> Your CV </span>
                        {this.state.resume && (
                          <a className='editOnReview' href={this.state.resume}>
                            {this.state.resumeFileName}
                          </a>
                        )}
                      </li>
                    </ul>
                    <div className='reviewSectionTop'>
                      <h3> Your Education </h3>
                      <Link className='editOnReview' to='/profile-step-2'>
                        <i className='far fa-edit' title='Edit' />
                      </Link>
                    </div>
                    {education &&
                      education.map((education, index) => {
                        return (
                          <ul key={index} className='signupReview'>
                            <li className='clearfix'>
                              <span> College/ University </span>
                              <strong> {education.institute} </strong>
                            </li>

                            <li>
                              <span> Degree </span>
                              <strong> {education.degree} </strong>
                            </li>
                            <li>
                              <span>Specialization</span>
                              <strong> {education.fieldOfStudy} </strong>
                            </li>
                            <li>
                              <span> CGPA </span>
                              <strong> {education.cgpa} </strong>
                            </li>
                            <li>
                              <span> Year </span>
                              <strong>
                                {education.from} to {education.to}
                              </strong>
                            </li>
                          </ul>
                        )
                      })}
                    {career &&
                      career.company &&
                      career.map((career, index) => {
                        return (
                          <ul key={index} className='signupReview'>
                            <li>
                              <span> Company </span>
                              <strong> {career.company} </strong>
                            </li>
                            <li>
                              <span> Position </span>
                              <strong> {career.position} </strong>
                            </li>
                            <li>
                              <span> Year </span>
                              <strong>
                                {career.from} to{' '}
                                {career.currentJob ? 'Present' : career.to}
                              </strong>
                            </li>
                          </ul>
                        )
                      })}
                    {/* <div className="reviewSectionTop">
                      <h3> Employment Questions </h3>
                      <Link className='editOnReview' to='/profile-step-3'>
                        <i className='far fa-edit' title='Edit' />
                      </Link>
                    </div> */}
                    {/* {employmentQuestions &&
                      employmentQuestions.map((eq, index) => {
                        return (
                          <ul key={index} className='signupReview'>
                            <li>
                              <span> {eq.statement} </span>
                              <strong> {eq.answer} </strong>
                            </li>
                          </ul>
                        );
                      })} */}

                    <div className='form-group'>
                      <input className='btn' type='submit' value='Submit' />
                    </div>
                  </div>
                </form>
                <ProfileFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
ProfileStepFive.propTypes = {
  getUser: PropTypes.func.isRequired,
  createProfileStepFive: PropTypes.func.isRequired,
  removeJobApplying: PropTypes.func.isRequired
}
export default connect(
  state => ({
    profile: state.auth.profile,
    user: state.auth.user,
    jobApplying: state.jobsData.jobApplying
  }),
  { getUser, createProfileStepFive, removeJobApplying }
)(withAlert(ProfileStepFive))
