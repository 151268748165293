import React, { Component } from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'
import PropType from 'prop-types'
import isEmpty from '../../validation/is-empty'
import Modal from 'react-bootstrap4-modal'
import { deleteQuestion } from '../../actions/questionActions'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

class Questions extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      show: false,
      question: {}
    }
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow (question) {
    this.setState({ show: true, question: question })
  }

  onDeleteClick (id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteQuestion(this.props.test_id, id)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    })
  }

  render () {
    const { questions } = this.props
    const { question } = this.state
    const showQuestionModal = (
      <Modal visible={this.state.show} onClickBackdrop={this.handleClose}>
        <div className='modal-header'>
          <h5 className='modal-title'>
            <span className='text-info'>{question.statement}</span>
          </h5>
          <button
            type='button'
            className='close'
            onClick={this.handleClose}
            aria-label='Close'
          >
            <span aria-hidden='true'>x</span>
          </button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <div className='col-6 '>
              <h5 className='text-success'>Correct Options</h5>
              {!isEmpty(question.correctOptions) && (
                <ul>
                  {question.correctOptions.map(option => (
                    <li className='text-secondary' key={option.option}>
                      {option.option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className='col-6 '>
              <h5 className='text-warning'>Wrong Options</h5>
              {!isEmpty(question.wrongOptions) && (
                <ul>
                  {question.wrongOptions.map(option => (
                    <li className='text-secondary' key={option.option}>
                      {option.option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {question.image !== null ? (
                <img src={question.image} style={{ width: '100%' }} />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/* <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.handleClose}
          >
            Close
          </button>
        </div> */}
      </Modal>
    )

    const columns = [
      {
        Header: 'Statement',
        accessor: 'statement'
      },
      {
        Header: 'Type',
        accessor: 'typeOfQuestion'
      },
      {
        Header: 'Correct Options',
        accessor: 'correctOptions',
        Cell: row => row.value.map(a => a.option).toString()
      },
      {
        Header: 'Wrong Options',
        accessor: 'wrongOptions',
        Cell: row => row.value.map(a => a.option).toString()
      },

      {
        Header: () => <span>Actions</span>,
        accessor: '_id',
        Cell: row => (
          <div>
            <i
              className='fa fa-trash-alt'
              aria-hidden='true'
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <span onClick={this.handleShow.bind(this, row.original)}>
              <i className='fas fa-eye' />
            </span>
          </div>
        )
      }
    ]

    return (
      <div className='profiles'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 mt-5'>
              <h1 className='display-5'>Questions Listing</h1>
              <p className='lead'>Browse and create Questions</p>
              {showQuestionModal}
              <ReactTable
                data={questions}
                defaultPageSize={10}
                columns={columns}
                filterable
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Questions.PropType = {
  questions: PropType.object.isRequired,
  deleteQuestion: PropType.func.isRequired
}

export default connect(
  null,
  { deleteQuestion }
)(Questions)
