import {
  GET_EMPLOYMENT_QUESTION,
  GET_EMPLOYMENT_QUESTIONS,
  DELETE_EMPLOYMENT_QUESTION
} from '../actions/types'

const initialState = {
  employmentQuestion: {},
  employmentQuestions: [],
  loading: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYMENT_QUESTION:
      return {
        ...state,
        employmentQuestion: action.payload
      }
    case GET_EMPLOYMENT_QUESTIONS:
      return {
        ...state,
        employmentQuestions: action.payload,
        loading: false
      }
    case DELETE_EMPLOYMENT_QUESTION:
      return {
        ...state,
        // remove deleted employmentQuestion from state to update page
        employmentQuestions: state.employmentQuestions.filter(
          employmentQuestion => employmentQuestion._id !== action.payload.id
        ),
        loading: false
      }
    default:
      return state
  }
}
