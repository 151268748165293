import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getUserEmploymentQuestions } from "../../actions/employmentQuestionsActions";
import { getUser } from "../../actions/authActions";
import { getJob, applyForJob } from "../../actions/jobActions";
import { withAlert } from "react-alert";

class EmploymentQuestions extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      employment_questions: [],
      answers: {},
      user: {}
    };
    this.onSubmit = this.onSubmit.bind(this);
    // this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    this.props.getJob(this.props.match.params.id);
  }

  onSubmit(e) {
    e.preventDefault();
    let answers = [];

    for (var key in this.state.answers) {
      if (this.state.answers.hasOwnProperty(key)) {
        console.log(key + " -> " + this.state.answers[key]);
        answers.push({
          employment_question_id: key,
          answer: this.state.answers[key]
        });
      }
    }

    // this.state.answers.forEach(answer => {
    //   answers.push({ _id: answer.key });
    // });
    this.props.applyForJob(
      this.props.job._id,
      this.props.user.id,
      { answers },
      this.props.history,
      this.props.alert
    );
  }

  onChange(e) {
    this.state.answers[e.target.title] = e.target.value;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.job.employment_questions) {
      this.setState({
        employment_questions: nextProps.job.employment_questions
      });
    }
    if (nextProps.user) {
      this.setState({
        user: nextProps.user
      });
    }
  }

  render() {
    const { job, user } = this.props;
    const { errors } = this.state;
    return (
      <div>
        <Navbar />
        <div className="signupStepSection signupbgTwo">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-10">
                <form onSubmit={this.onSubmit}>
                  <div className="loginForm">
                    <h3>
                      Job Title: <strong>{job.title}</strong>
                      <hr />
                    </h3>
                    <h3>
                      Please answer the following questions to apply for this
                      Job
                    </h3>
                    <hr />
                    <br />
                    <div className="row employmentQuestions">
                      {job.employment_questions &&
                        job.employment_questions
                          // .slice(0, 3)
                          .map((question, index) => {
                            return (
                              <div key={index} className="col-12">
                                <div className="form-group">
                                  <label>{`${index + 1}. ${
                                    question.title
                                  }`}</label>
                                  <input
                                    type="hidden"
                                    name={"statement" + (index + 1)}
                                  />
                                  <textarea
                                    className="form-control"
                                    name={"answer" + (index + 1)}
                                    title={question._id}
                                    maxLength={question.limit}
                                    // value={`${
                                    //   this.state["answer" + (index + 1)] ==
                                    //   undefined
                                    //     ? ""
                                    //     : this.state["answer" + (index + 1)]
                                    // }`}
                                    onChange={this.onChange.bind(this)}
                                    placeholder="Your Answer"
                                    required
                                  />
                                  {errors.answer && errors.answer[index] && (
                                    <div className="is-invalid">
                                      {errors.answer[index]}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                    </div>

                    <div className="form-group">
                      <input type="submit" value="Submit" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmploymentQuestions.propTypes = {
  getUserEmploymentQuestions: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  applyForJob: PropTypes.func.isRequired
};

export default connect(
  state => ({
    employment_questions: state.employmentQuestionsData.employmentQuestions,
    errors: state.errors,
    user: state.auth.user,
    profile: state.auth.profile,
    job: state.jobsData.job
  }),
  { getUserEmploymentQuestions, getUser, getJob, applyForJob }
)(withAlert(EmploymentQuestions));
