import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { getTest, updateTest } from '../../actions/testActions'
import { getDepartments } from '../../actions/departmentActions'
import { connect } from 'react-redux'
import SelectListGroup from '../common/SelectListGroup'
import Navbar from '../layout/Navbar'
import { withAlert } from 'react-alert'

class EditTest extends Component {
  constructor () {
    super()
    this.state = {
      title: '',
      level: '',
      department: '',
      time: '',
      totalQuestions: '',
      aptitudePercentage: '',
      technicalPercentage: '',
      departments: [],
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  onSubmit (e) {
    e.preventDefault()
    const editTest = {
      title: this.state.title,
      level: this.state.level,
      department: this.state.department,
      time: this.state.time,
      totalQuestions: this.state.totalQuestions,
      aptitudePercentage: this.state.aptitudePercentage,
      technicalPercentage: this.state.technicalPercentage
    }

    console.log(editTest)
    this.props.updateTest(
      this.props.match.params.id,
      editTest,
      this.props.history,
      this.props.alert
    )
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount () {
    this.props.getTest(this.props.match.params.id)
    this.props.getDepartments()
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments })
    }
    if (nextProps.test) {
      const test = nextProps.test
      // Set Component State Field
      this.setState({
        title: test.title,
        level: test.level,
        department: test.department,
        time: test.time,
        totalQuestions: test.totalQuestions,
        aptitudePercentage: test.aptitudePercentage,
        technicalPercentage: test.technicalPercentage
      })
    }
  }

  render () {
    const { errors } = this.state

    const { departments } = this.state

    const deptOptions = []
    for (var i = 0; i < departments.length; i++) {
      deptOptions.push({
        label: departments[i].title,
        value: departments[i]._id
      })
    }

    return (
      <div>
        <Navbar />
        <div className='register'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 m-auto'>
                <h1 className='display-4 text-center'>Edit this Test</h1>
                <p className='lead text-center'>Fields</p>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.title
                      })}
                      placeholder='Title'
                      name='title'
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className='invalid-feedback'>{errors.title}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label className='label info'>Time </label>
                    <input
                      type='number'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.time
                      })}
                      placeholder='Time'
                      name='time'
                      value={this.state.time}
                      onChange={this.onChange}
                    />
                    {errors.time && (
                      <div className='invalid-feedback'>{errors.time}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <select
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.totalQuestions
                      })}
                      placeholder='Test Total Questions'
                      name='totalQuestions'
                      value={this.state.totalQuestions}
                      onChange={this.onChange}
                    >
                      <option disabled defaultValue>
                        Select Test Total Questions
                      </option>
                      <option value='10'> 10</option>
                      <option value='20'> 20</option>
                      <option value='30'> 30</option>
                      <option value='40'> 40</option>
                      <option value='50'> 50</option>
                      <option value='60'> 60</option>
                    </select>
                    {errors.level && (
                      <div className='invalid-feedback'>
                        {errors.totalQuestions}
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label className='label info'>Aptitude Percentage </label>
                    <input
                      type='number'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.aptitudePercentage
                      })}
                      placeholder='Aptitude Questions %'
                      name='aptitudePercentage'
                      value={this.state.aptitudePercentage}
                      onChange={this.onChange}
                    />
                    {errors.aptitudePercentage && (
                      <div className='invalid-feedback'>
                        {errors.aptitudePercentage}
                      </div>
                    )}
                  </div>

                  <div className='form-group'>
                    <label className='label info'>Technical Percentage </label>
                    <input
                      type='number'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.technicalPercentage
                      })}
                      placeholder='Technical Questions %'
                      name='technicalPercentage'
                      value={this.state.technicalPercentage}
                      onChange={this.onChange}
                    />
                    {errors.technicalPercentage && (
                      <div className='invalid-feedback'>
                        {errors.technicalPercentage}
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <select
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.level
                      })}
                      placeholder='Test Level'
                      name='level'
                      value={this.state.level}
                      onChange={this.onChange}
                    >
                      <option value='' selected>
                        Select Test Level
                      </option>
                      <option value='entry'> Entry</option>
                      <option value='mid'> Mid</option>
                      <option value='advanced'> Advanced</option>
                    </select>
                    {errors.level && (
                      <div className='invalid-feedback'>{errors.level}</div>
                    )}
                  </div>
                  <SelectListGroup
                    placeholder='Department'
                    name='department'
                    value={this.state.department}
                    onChange={this.onChange}
                    options={deptOptions}
                    error={errors.department}
                    info='Select the department for this Test'
                  />
                  {errors.department && (
                    <div className='invalid-feedback'>{errors.department}</div>
                  )}
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditTest.propTypes = {
  getTest: PropTypes.func.isRequired,
  updateTest: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getDepartments: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  errors: state.errors,
  test: state.testsData.test,
  departments: state.departmentsData.departments
})

export default connect(
  mapStateToProps,
  {
    getTest,
    updateTest,
    getDepartments
  }
)(withAlert(EditTest))
