export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CREATE_JOB = 'CREATE_JOB'
export const GET_JOBS = 'GET_JOBS'
export const JOB_LOADING = 'JOB_LOADING'
export const GET_JOB = 'GET_JOB'
export const DELETE_JOB = 'DELETE_JOB'
export const GET_TEST = 'GET_TEST'
export const GET_TESTS = 'GET_TESTS'
export const TEST_LOADING = 'TEST_LOADING'
export const DELETE_TEST = 'DELETE_TEST'
export const UPDATE_TEST = 'UPDATE_TEST'
export const GET_EMPLOYMENT_QUESTION = 'GET_EMPLOYMENT_QUESTION'
export const GET_EMPLOYMENT_QUESTIONS = 'GET_EMPLOYMENT_QUESTIONS'
export const EMPLOYMENT_QUESTION_LOADING = 'EMPLOYMENT_QUESTION_LOADING'
export const DELETE_EMPLOYMENT_QUESTION = 'DELETE_EMPLOYMENT_QUESTION'
export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANIES = 'GET_COMPANIES'
export const COMPANY_LOADING = 'COMPANY_LOADING'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const GET_DEPARTMENT = 'GET_DEPARTMENT'
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const DEPARTMENT_LOADING = 'DEPARTMENT_LOADING'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
export const CREATE_PROFILE_STEP_ONE = 'CREATE_PROFILE_STEP_ONE'
export const CREATE_PROFILE_STEP_TWO = 'CREATE_PROFILE_STEP_TWO'
export const CREATE_PROFILE_STEP_THREE = 'CREATE_PROFILE_STEP_THREE'
export const CREATE_PROFILE_STEP_FOUR = 'CREATE_PROFILE_STEP_FOUR'
export const CREATE_PROFILE_STEP_FIVE = 'CREATE_PROFILE_STEP_FIVE'
export const GET_USER = 'GET_USER'
export const APPLY_FOR_JOB = 'APPLY_FOR_JOB'
export const SHORTLIST_APPLICANT = 'SHORTLIST_APPLICANT'
export const REMOVE_APPLICANT = 'REMOVE_APPLICANT'
export const GET_EDUCATION = 'GET_EDUCATION'
export const GET_EDUCATIONS = 'GET_EDUCATIONS'
export const EDUCATION_LOADING = 'EDUCATION_LOADING'
export const DELETE_EDUCATION = 'DELETE_EDUCATION'
export const CHANGE_JOB_STATUS = 'CHANGE_JOB_STATUS'
export const GET_USER_JOB = 'GET_USER_JOB'
export const GET_USER_JOBS = 'GET_USER_JOBS'
export const UPDATE_USER_TEST = 'UPDATE_USER_TEST'
export const UPDATE_USER_TEST_TIME = 'UPDATE_USER_TEST_TIME'
export const GET_ALL_USER_JOBS = 'GET_ALL_USER_JOBS'
export const REMOVE_JOB_APPLYING = 'REMOVE_JOB_APPLYING'
export const SET_JOB_APPLYING = 'SET_JOB_APPLYING'
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const GET_ALL_USER_TESTS = 'GET_ALL_USER_TESTS'
export const GET_FLASH = 'GET_FLASH'
