import isEmpty from "../validation/is-empty";

import {
  SET_CURRENT_USER,
  CREATE_PROFILE_STEP_ONE,
  CREATE_PROFILE_STEP_TWO,
  GET_USER,
  CONFIRM_EMAIL
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
  profile: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case CREATE_PROFILE_STEP_ONE:
      return {
        ...state,
        profile: action.payload
      };
    case CREATE_PROFILE_STEP_TWO: {
      return {
        ...state,
        profile: action.payload
      };
    }
    case GET_USER: {
      return {
        ...state,
        profile: action.payload
      };
    }
    case CONFIRM_EMAIL: {
      return {
        ...state,
        confirmEmail: action.payload
      };
    }
    default:
      return state;
  }
}
