import {
  UPDATE_USER_TEST,
  UPDATE_USER_TEST_TIME,
  GET_ALL_USER_TESTS
} from "../actions/types";

const initialState = {
  questions: [],
  time: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_TEST:
      return {
        ...state,
        questions: state.questions.concat(...action.payload)
      };
    case UPDATE_USER_TEST_TIME:
      return {
        ...state,
        time: action.payload
      };
    case GET_ALL_USER_TESTS:
      return {
        ...state,
        allUserTests: action.payload
      };
    default:
      return state;
  }
}
