import axios from 'axios'

import {
  GET_ERRORS,
  GET_TEST,
  GET_TESTS,
  TEST_LOADING,
  DELETE_TEST,
  GET_FLASH
} from './types'

// Get all Tests
export const getTests = () => dispatch => {
  dispatch(setTestLoading())
  axios
    .get('/api/admin/tests')
    .then(res => {
      dispatch({
        type: GET_TESTS,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }

      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    })
}

// Get Specific Tests based on department ID
export const getTestsAccToDepartment = departmentId => dispatch => {
  dispatch(setTestLoading())
  axios
    .get('/api/admin/tests' + '?department_id=' + departmentId)
    .then(res =>
      dispatch({
        type: GET_TESTS,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    })
}

// create New test
export const createTest = (testData, history, alert) => dispatch => {
  axios
    .post('/api/admin/tests/create', testData)
    .then(res => {
      history.push('/tests/show/' + res.data._id)
      alert.show('Test has been created.')
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Get Single test
export const getTest = id => dispatch => {
  dispatch(setTestLoading())
  axios
    .get('/api/admin/tests/' + id)
    .then(res => {
      console.log(res.data)
      dispatch({
        type: GET_TEST,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response // .data
      })
    })
}

// Update single Test
export const updateTest = (id, testData, history, alert) => dispatch => {
  axios
    .post('/api/admin/tests/update/' + id, testData)
    .then(res => {
      history.push('/tests')
      alert.show('Test has been updated.')
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Delete Test
export const deleteTest = id => dispatch => {
  // dispatch(setJobLoading());
  axios
    .delete(`/api/admin/tests/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_TEST,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    )
}

// Set Loader icon untill state changes
export const setTestLoading = () => {
  return {
    type: TEST_LOADING
  }
}
