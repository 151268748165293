import React, { Component } from "react";
import InstagramSection from "../layout/landing/InstagramSection";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getJob, applyForJob, setJobApplying } from "../../actions/jobActions";
import { getUser } from "../../actions/authActions";
import { getPublicDepartments } from "../../actions/departmentActions";
import Navbar from "../layout/Navbar";
import isEmpty from "../../validation/is-empty";
import Footer from "../layout/Footer";
import moment from "moment";
import { MOMENT_FORMAT } from "../../globals/variables";
import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
  FacebookShareCount,
} from "react-share";
import { withAlert } from "react-alert";

class JobDetail extends Component {
  constructor() {
    super();
    this.state = {
      job: "",
      user: "",
      departments: [],
      errors: {},
      fromLat: "",
      fromLng: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    // Bind the openMap method to the component's context
    this.openMap = this.openMap.bind(this);
  }

  componentDidMount() {
    // Get the current location using the Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            fromLat: position.coords.latitude,
            fromLng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }

    if (this.props.match.params.id) {
      this.props.getJob(this.props.match.params.id);
    }
    if (isEmpty(this.props.profile)) {
      this.props.getUser(this.props.user.id);
    }
    this.props.getPublicDepartments();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.job) {
      this.setState({ job: nextProps.job });
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments });
    }
    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }
  onSubmit(e) {
    e.preventDefault();
    if (isEmpty(this.props.user)) {
      this.props.history.push({
        pathname: "/register",
        search: "?job_id=" + this.props.job._id,
      });
    } else if (!this.props.profile.termsAndConditions) {
      this.props.alert.show(`Please Complete your profile before applying.`);
      this.props.history.push("/profile-step-1");
    } else if (!isEmpty(this.props.user)) {
      this.props.setJobApplying(this.props.job._id);
      this.props.history.push("/profile-step-5");
      // this.props.history.push(
      //   `/jobs/apply/${this.state.job._id}/employmentQuestions`
      // );
      // this.props.applyForJob(
      //   this.props.job._id,
      //   this.props.user.id,
      //   this.props.history
      // );
    } else {
    }
  }

  openMap() {
    const { fromLat, fromLng, job } = this.state;
    const { toLat, toLng } = job;
    const url = `https://www.google.com/maps/dir/${fromLat},${fromLng}/${toLat},${toLng}`;
    // const url =
    //   "https://www.google.com/maps/dir/40.712776,-74.005974/34.052235,-118.243683";
    window.open(url, "_blank"); // Open in a new window or tab
  }

  render() {
    const { user, job } = this.state;
    const url = window.location.origin + "/job-detail/" + job._id;
    const title =
      "Apply at NCL Recruitment Portal for the post of " + job.title;
    return (
      <div>
        <Navbar />
        <div className="innerPageBannerWrap">
          <div
            className="innerPageBanner"
            style={{
              backgroundImage: `url(${require("../../images/job-listing-banner.jpg")})`,
            }}
          >
            <div className="bannerContainer container">
              <div className="bannerContent">
                <div className="jobDetailBanner">
                  <h1> {job.title}</h1>
                  {/* <span> Job ID: {this.state.job._id} </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="innerPageContentWrap">
          <div className="innerPageContentContainer container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="jobDetailsLeft">
                  <ul>
                    <li>
                      Job Type: <strong> {job.job_type} </strong>
                    </li>
                    <li>
                      Location: <strong> {job.location} </strong>
                    </li>
                    <li>
                      Experience required:{" "}
                      <strong> {job.experience} years </strong>
                    </li>
                    <li>
                      Education required:
                      <strong>
                        {job &&
                          job.educations &&
                          job.educations.map((a) => a.title + " ")}
                      </strong>
                    </li>
                    {/* <li>
                      Posting Date:
                      <strong>
                        {moment(job.start_date).format(MOMENT_FORMAT)}
                      </strong>
                    </li> */}
                    <li>
                      End Date:&nbsp;
                      <strong>
                        {moment(job.end_date).format(MOMENT_FORMAT)}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="jobDetailsRight">
                  <div className="jobShareDropdown jobShareDropdownDp">
                    <button
                      className="dropdown-toggle mr-1 buttonStyle"
                      type="button"
                      // id="jobShareDpButton"
                      // data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={this.openMap}
                    >
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </button>
                    <button
                      className="dropdown-toggle"
                      type="button"
                      id="jobShareDpButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-share-alt" />
                    </button>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="jobShareDpButton"
                    >
                      <ul>
                        <li>
                          <a>
                            <FacebookShareButton
                              url={url}
                              quote={title}
                              className="button"
                            >
                              <i className="fab fa-facebook-f" />
                            </FacebookShareButton>
                          </a>
                        </li>
                        <li>
                          <a>
                            <TwitterShareButton
                              url={url}
                              title={title}
                              className="button"
                            >
                              <i className="fab fa-twitter" />
                            </TwitterShareButton>
                          </a>
                        </li>
                        <li>
                          <a>
                            <GooglePlusShareButton
                              url={url}
                              title={title}
                              className="button"
                            >
                              <i className="fab fa-google-plus-g" />
                            </GooglePlusShareButton>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <h2> Job Details </h2>

                  <h3> Job Description: </h3>
                  <p>{job.ex}</p>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.job.description,
                      }}
                    />
                  </p>

                  <h3> Key Requirements: </h3>

                  {/* <p>
                    <ul>
                      {job &&
                        job.skills &&
                        job.skills[0] &&
                        job.skills.map((skill) => {
                          return <li>{skill}</li>;
                        })}
                    </ul> */}
                  {/* BBA / MBA (Public Administration/IR & HR) Must have at least
                    3-7 years working experience of Administration Department or
                    2-4 years of Plant Administration experience. */}
                  {/* </p> */}

                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.job.skills,
                      }}
                    />
                  </p>
                </div>

                {!isEmpty(job) && job.applicants.indexOf(user.id) >= 0 && (
                  <a className="siteBtn1" style={{ color: "#35b75d" }}>
                    <span className="verified">
                      <i
                        className="fa fa-check-circle fa-2x"
                        style={{ color: "#35b75d" }}
                        title="You've applied for this position"
                      />
                    </span>
                  </a>
                )}

                {!(!isEmpty(job) && job.applicants.indexOf(user.id) >= 0) && (
                  <form noValidate onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button
                          type="submit"
                          className="siteBtn jobApplyDetailBtn"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        <InstagramSection />
        <Footer />
      </div>
    );
  }
}

JobDetail.protoTypes = {
  getJob: PropTypes.func.isRequired,
  getPublicDepartments: PropTypes.func.isRequired,
  departments: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  applyForJob: PropTypes.func.isRequired,
  setJobApplying: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    job: state.jobsData.job,
    departments: state.departmentsData.departments,
    user: state.auth.user,
    profile: state.auth.profile,
    errors: state.errors,
  }),
  { getJob, getPublicDepartments, applyForJob, setJobApplying, getUser }
  // )(JobDetail);
)(withAlert(JobDetail));