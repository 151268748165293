import axios from 'axios'

import {
  GET_ERRORS,
  GET_EMPLOYMENT_QUESTION,
  GET_EMPLOYMENT_QUESTIONS,
  DELETE_EMPLOYMENT_QUESTION,
  EMPLOYMENT_QUESTION_LOADING
} from './types'

// Create an Employment Question
export const createEmploymentQuestion = (
  employmentQuestionsData,
  history
) => dispatch => {
  axios
    .post('/api/admin/employment_questions/create', employmentQuestionsData)
    .then(res => history.push('/employment_questions'))
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Delete Employment Question
export const deleteEmploymentQuestion = id => dispatch => {
  dispatch(setEmploymentQuestionLoading())
  axios
    .delete(`/api/admin/employment_questions/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_EMPLOYMENT_QUESTION,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    })
}

// Get an Employment Question
export const getEmploymentQuestion = id => dispatch => {
  axios
    .get('/api/admin/employment_questions/' + id)
    .then(res => {
      dispatch({
        type: GET_EMPLOYMENT_QUESTION,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Get all EmploymentQuestions
export const getEmploymentQuestions = () => dispatch => {
  dispatch(setEmploymentQuestionLoading())
  axios
    .get('/api/admin/employment_questions')
    .then(res =>
      dispatch({
        type: GET_EMPLOYMENT_QUESTIONS,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    })
}

// Update New Job
export const updateEmploymentQuestion = (
  id,
  employmentQuestionData,
  history
) => dispatch => {
  axios
    .post(
      '/api/admin/employment_questions/update/' + id,
      employmentQuestionData
    )
    .then(res => history.push('/employment_questions'))
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

export const getUserEmploymentQuestions = () => dispatch => {
  dispatch(setEmploymentQuestionLoading())
  axios
    .get('/api/public/employment_questions')
    .then(res =>
      dispatch({
        type: GET_EMPLOYMENT_QUESTIONS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    )
}

// Set Loader icon untill state changes
export const setEmploymentQuestionLoading = () => {
  return {
    type: EMPLOYMENT_QUESTION_LOADING
  }
}
