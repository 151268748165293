import React, { Component } from "react";
import NavLinks from "./NavLinks";
export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div id="hamburgerIcon" class="hamburgerSideNav">
          <span />
          <span /> <span /> <span /> <span /> <span />
        </div>

        <div class="sideBar" id="navSidebar">
          <nav class="sideNav">
            <NavLinks />
          </nav>
        </div>
      </div>
    );
  }
}
