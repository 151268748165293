import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { createEducation } from '../../actions/educationActions'
import { connect } from 'react-redux'
import Navbar from '../layout/Navbar'

class CreateEducation extends Component {
  constructor () {
    super()
    this.state = {
      title: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (e) {
    e.preventDefault()

    const newEducation = {
      title: this.state.title
    }
    this.props.createEducation(newEducation, this.props.history)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { errors } = this.state

    return (
      <div>
        <Navbar />
        <div className='register'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 m-auto'>
                <h1 className='display-4 text-center'>Add a Degree</h1>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <label>Title</label>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.title
                      })}
                      placeholder='Title'
                      name='title'
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className='invalid-feedback'>{errors.title}</div>
                    )}
                  </div>
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CreateEducation.propTypes = {
  createEducation: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(
  mapStateToProps,
  { createEducation }
)(withRouter(CreateEducation))
