import axios from "axios";

import { GET_ERRORS, GET_TEST, TEST_LOADING, GET_ALL_USER_JOBS } from "./types";

// Get Single job
export const getAllUserJobs = () => dispatch => {
  dispatch(setJobLoading());
  axios
    .get("/api/user_jobs/all/user-jobs")
    .then(res => {
      dispatch({
        type: GET_ALL_USER_JOBS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response // .data
      });
    });
};

// Set Loader icon untill state changes
export const setJobLoading = () => {
  return {
    type: TEST_LOADING
  };
};

export const testTaken = (user_id, job_id, value) => dispatch => {
  // dispatch(setJobLoading());
  axios
    .post("/api/user_jobs/testTaken", {
      user_id,
      job_id,
      value
    })
    .then(res => {})
    .catch(err => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response // .data
      // });
    });
};
