import React from "react";

export default function Process() {
  return (
    <div className="row" id="ourProcessSection">
      <div
        className="col-lg-5 ourProcess wow slideInLeft"
        data-wow-duration="1500ms"
        data-wow-delay="600ms"
      >
        <h2> What is our Process? </h2>

        <p>
          We strive to attract, develop and retain high-performing talent who
          are a good fit for our corporate culture, while continuously meeting
          our current and future business needs.
        </p>
        <p>Our recruitment process comprises of five phases:</p>
      </div>
      <div
        className="col-lg-7 processTabsSection wow slideInRight"
        data-wow-duration="1500ms"
        data-wow-delay="600ms"
      >
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="processTabsContentSection">
              <div className="tab-content" id="">
                <div
                  className="tab-pane fade"
                  id="processTab1"
                  role="tabpanel"
                  aria-labelledby="hire-tab"
                >
                  <div className="processtabContent">
                    <strong> Shortlisting </strong>
                    <p>
                      Candidates’ profile screening is based on the eligibility
                      criteria set for each position.
                    </p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="processTab2"
                  role="tabpanel"
                  aria-labelledby="develop-tab"
                >
                  <div className="processtabContent">
                    <strong> Assessment </strong>
                    <p>
                      Aptitude and Technical Assessments take place to give us
                      an idea if you have the abilities to meet the unique
                      challenges of our business.
                    </p>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="processTab3"
                  role="tabpanel"
                  aria-labelledby="grow-tab"
                >
                  <div className="processtabContent">
                    <strong> Interview </strong>

                    <p>
                      The length and format will vary according to role and
                      location. It gives you the chance to show us the skills
                      and knowledge that make you a perfect fit for the role.
                    </p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="processTab4"
                  role="tabpanel"
                  aria-labelledby="grow-tab"
                >
                  <div className="processtabContent">
                    <strong> Evaluation </strong>
                    <p>
                      Final stage of assessing potential candidates who are most
                      suitable for the position.
                    </p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="processTab5"
                  role="tabpanel"
                  aria-labelledby="grow-tab"
                >
                  <div className="processtabContent">
                    <strong> Background Check </strong>
                    <p>
                      Candidates are asked to share details for background
                      check. Upon successful completion of the process, a job
                      offer is placed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <ul className="nav processTabsNav" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link"
                  id=""
                  data-toggle="tab"
                  href="#processTab1"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Shortlisting
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id=""
                  data-toggle="tab"
                  href="#processTab2"
                  role="tab"
                  aria-controls="develop"
                  aria-selected="false"
                >
                  Assessment
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id=""
                  data-toggle="tab"
                  href="#processTab3"
                  role="tab"
                  aria-controls="grow"
                  aria-selected="false"
                >
                  Interview
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id=""
                  data-toggle="tab"
                  href="#processTab4"
                  role="tab"
                  aria-controls="grow"
                  aria-selected="false"
                >
                  Evaluation
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id=""
                  data-toggle="tab"
                  href="#processTab5"
                  role="tab"
                  aria-controls="grow"
                  aria-selected="false"
                >
                  Background Check
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
