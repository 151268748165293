import axios from 'axios'

import {
  GET_ERRORS,
  GET_TEST,
  UPDATE_USER_TEST,
  TEST_LOADING,
  UPDATE_USER_TEST_TIME,
  GET_ALL_USER_TESTS
} from './types'

export const updateTime = time => dispatch => {
  dispatch({
    type: UPDATE_USER_TEST_TIME,
    payload: time
  })
}

export const createUserTest = (
  testConductData,
  formEnd,
  stateQuestions,
  history
) => dispatch => {
  const questions = testConductData.questions
  dispatch({
    type: UPDATE_USER_TEST,
    payload: questions
  })
  if (formEnd) {
    let newUserTest = {
      test: testConductData.test,
      questions: stateQuestions,
      user: testConductData.user
    }
    axios
      .post('/api/user_tests/create', newUserTest)
      .then(res => {
        history.push('/')
        alert(
          'Thank you for taking the test. You time is over and your test has been submitted.'
        )
        localStorage.removeItem('testTime');
        localStorage.removeItem('totalQuestionsCount');
        window.location.reload();
      })
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      )
  }
}

// export const createUserTest = (testConductData, history) => dispatch => {
// axios
//   .post('/api/user_tests/create', testConductData)
//   .then(res => history.push('/'))
//   .catch(err =>
//     dispatch({
//       type: GET_ERRORS,
//       payload: err.response.data
//     })
//   )
// }

export const updateUserTest = (id, testConductData, history) => dispatch => {
  axios
    .post('/api/user_tests/update' + id, testConductData)
    .then(res => history.push('/'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    )
}

// Get Single test
export const getTest = id => dispatch => {
  dispatch(setTestLoading())
  axios
    .get('/api/tests/' + id)
    .then(res => {
      localStorage.setItem('totalQuestionsCount', res.data.questions.length);
      dispatch({
        type: GET_TEST,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response // .data
      })
    )
}

export const getAllUserTests = () => dispatch => {
  dispatch(setTestLoading())
  axios
    .get('/api/user_tests/')
    .then(res => {
      dispatch({
        type: GET_ALL_USER_TESTS,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response // .data
      })
    })
}

// Set Loader icon untill state changes
export const setTestLoading = () => {
  return {
    type: TEST_LOADING
  }
}
