import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  CREATE_PROFILE_STEP_ONE,
  CREATE_PROFILE_STEP_TWO,
  CREATE_PROFILE_STEP_THREE,
  CREATE_PROFILE_STEP_FOUR,
  GET_USER,
  CONFIRM_EMAIL,
} from "./types";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => {
      dispatch({
        type: CONFIRM_EMAIL,
        payload: true,
      });

      history.push("/login");
    })
    // .then(res => {
    //   // Save to localStorage
    //   const { token } = res.data;
    //   // Set token to ls
    //   localStorage.setItem("jwtToken", token);
    //   localStorage.setItem("role", res.data.role);
    //   // Set token to Auth header
    //   setAuthToken(token);
    //   // Decode token to get user data
    //   const decoded = jwt_decode(token);
    //   // Set current user
    //   dispatch(setCurrentUser(decoded));
    // })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response, // .data
      });
    });
};

// Forgot password action
export const forgotPassword = (data, history, alert) => (dispatch) => {
  axios
    .post("/api/users/forgot_password", data)
    .then((res) => {
      history.push("/login");
      alert.show(
        "An email with password instructions has been sent to your email."
      );
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response, // .data
      });
    });
};

// Reset password action
export const resetPassword = (data, history, alert) => (dispatch) => {
  axios
    .post("/api/users/reset/", data)
    .then((res) => {
      history.push("/login");
      alert.show("Your password has been Resetted successfully.");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response, // .data
      });
    });
};

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("role", res.data.role);
      localStorage.removeItem("testTime");
      localStorage.removeItem("totalQuestionsCount");
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const changePassword = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/change_password", userData)
    .then((res) => {
      alert("Your password has been changed Successfully");
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token form local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("testTime");
  localStorage.removeItem("totalQuestionsCount");
  window.location.reload();
  // remove auth header sfor future requests
  setAuthToken(false);
  // set current user to { } which will also set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const getUser = (id) => (dispatch) => {
  axios
    .get(`/api/users/${id}`)
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createProfileStepOne = (profileData, history) => (dispatch) => {
  console.log(profileData);
  axios
    .post("/api/users/create/step-one", profileData)
    .then((res) => {
      dispatch({
        type: CREATE_PROFILE_STEP_ONE,
        payload: res.data,
      });
      history.push("/profile-step-2");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createProfileStepTwo = (profileData, history) => (dispatch) => {
  console.log(profileData);
  axios
    .post("/api/users/create/step-two", profileData)
    .then((res) => {
      dispatch({
        type: CREATE_PROFILE_STEP_TWO,
        payload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      history.push("/profile-step-4");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// export const createProfileStepThree = (profileData, history) => dispatch => {
//   console.log(profileData);
//   axios
//     .post("/api/users/create/step-three", profileData)
//     .then(res => {
//       dispatch({
//         type: CREATE_PROFILE_STEP_THREE,
//         payload: res.data
//       });
//       history.push("/profile-step-4");
//     })
//     .catch(err => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data
//       });
//     });
// };

export const createProfileStepFour = (profileData, history) => (dispatch) => {
  console.log(profileData);
  axios
    .post("/api/users/create/step-four", profileData)
    .then((res) => {
      dispatch({
        type: CREATE_PROFILE_STEP_FOUR,
        payload: res.data,
      });
      // Call the custom function (pass any data or arguments if needed)
      // sendCvSubmittedMail(res.data);
      sendCvSubmittedMail(profileData);
      history.push("/profile-step-5");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
}; // Example custom function
const sendCvSubmittedMail = (data) => {
  console.log("Custom function called with data:", data);
  axios.post("/api/public/send-CvSubmit-email", data).then((res) => {
    // alert.show("Mail has been Sent.");
  });
};
export const createProfileStepFive = (props) => (dispatch) => {
  props.history.push("/careers");
  props.alert.show(`Your profile has been submitted successfully.`);
};

export const confirmUser = (uuid, history) => (dispatch) => {
  console.log("UUID: " + uuid);
  axios
    .post("/api/users/confirm/" + uuid)
    .then((res) => {
      history.push("/login");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const confirmEmailFlag = (val) => (dispatch) => {
  dispatch({
    type: CONFIRM_EMAIL,
    payload: val,
  });
};