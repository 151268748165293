import React from 'react'
import { Link } from 'react-router-dom'

export default () => {
  return (
    // <footer className="bg-dark text-white mt-5 p-4 text-center">
    //   Copyright &copy; {new Date().getFullYear()} NRP Jobs
    // </footer>
    <div>
      <footer className='siteFooter' id='siteFooter'>
        <div className='container-fluid footertop'>
          <div className='siteLogoFooter wow fadeInUp'>
            <Link to='/'>
              <img src={require('../../images/site-logo.png')} alt='Logo' />
            </Link>
          </div>
          <div className='contactDetails wow fadeInUp'>
            <ul>
              <li> 31-Q, Gulberg II Lahore 54660,Pakistan</li>
              <li> +92 42 35761730-9 | +92 42 35878696-7 </li>
            </ul>
          </div>
        </div>

        <div className='container-fluid footerBottom'>
          <nav className='footerNav wow fadeInUp'>
            <ul>
              <li>
                <a href='http://www.nishat.net/about-us'> About Us</a>
              </li>
              <li>
                <a href='http://www.nishat.net/businesses'>Businesses</a>
              </li>
              <li>
                <a href='http://www.nishat.net/corporate-governance'>Corporate Governance</a>
              </li>
              <li>
                <a href='http://www.nishat.net/investor-relations'>Investor Relations </a>
              </li>
              <li>
                <a href='http://www.nishat.net/csr'>CSR </a>
              </li>
              <li>
                <a href='/'>Careers </a>
              </li>
              <li>
                <a href='http://www.nishat.net/contact-us'>Contact </a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
      {/* <div className="socialIcons ">
        <ul>
          <li>
            <a href="/">
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a href="/">
              <i className="fab fa-linkedin-in" />
            </a>
          </li>
          <li>
            <a href="/">
              <i className="fas fa-envelope" />
            </a>
          </li>
          <ul />
        </ul>
      </div> */}
    </div>
  )
}
