import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logoutUser } from '../../actions/authActions'
import { Link } from 'react-router-dom'

class Header extends Component {
  onLogoutClick (e) {
    e.preventDefault()
    this.props.logoutUser()
  }
  render () {
    const { isAuthenticated } = this.props.auth
    const authLinks = (
      <ul>
        <li>
          <a
            href='/'
            onClick={this.onLogoutClick.bind(this)}
            className='nav-link'
          >
            Logout
          </a>
        </li>
      </ul>
    )
    const guestLinks = (
      <ul>
        <li>
          <Link className='nav-link1' to='/login'>
            Login
          </Link>
          {/* <a href="login.php">Login</a> */}
        </li>
        <li>
          <Link className='nav-link1' to='/register'>
            Sign Up
          </Link>
          {/* <a href="signup.php">Signup</a> */}
        </li>
      </ul>
    )
    return (
      <header className='siteHeader'>
        <div className='headerContainer container-fluid clearfix'>
          <div className='siteLogo'>
            <Link to='/'>
              <img src={require('../../images/site-logo.svg')} alt='Logo' />
            </Link>
          </div>

          <div className='userDropdown'>
            <button
              className='dropdown-toggle'
              type='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span>
                <i className='far fa-user-circle' />
              </span>
            </button>
            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
              {isAuthenticated ? authLinks : guestLinks}
            </div>
          </div>

          <nav className='mainNav'>
            <ul>
              {/* <li>
                <Link to='http://www.nishat.net'>Home</Link>
              </li> */}
              <li>
                <a href='http://www.nishat.net/about-us'> About Us</a>
              </li>
              <li>
                <a href='http://www.nishat.net/businesses'>Businesses</a>
              </li>
              <li>
                <a href='http://www.nishat.net/corporate-governance'>Corporate Governance</a>
              </li>
              <li>
                <a href='http://www.nishat.net/investor-relations'>Investor Relations </a>
              </li>
              <li>
                <a href='http://www.nishat.net/csr'>CSR</a>
              </li>
              <li>
                <Link to='/'>Careers</Link>
                {/* <a href="/">Careers </a> */}
              </li>
              <li>
                <a href='http://www.nishat.net/contact-us'>Contact </a>
              </li>
              {/* <li>{isAuthenticated && authLinks}</li> */}
              {/* <li>{!isAuthenticated && guestLinks}</li> */}
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(
  mapStateToProps,
  { logoutUser }
)(Header)
