import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  updateDepartment,
  getDepartment
} from '../../actions/departmentActions'
import { connect } from 'react-redux'
import Navbar from '../layout/Navbar'

class EditDepartment extends Component {
  constructor () {
    super()
    this.state = {
      title: '',
      description: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (e) {
    e.preventDefault()

    const editDepartment = {
      title: this.state.title,
      description: this.state.description
    }
    this.props.updateDepartment(
      this.props.match.params.id,
      editDepartment,
      this.props.history
    )
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
    if (nextProps.department) {
      const department = nextProps.department
      // Set Component State Field
      this.setState({
        title: department.title,
        description: department.description
      })
    }
  }

  componentDidMount () {
    this.props.getDepartment(this.props.match.params.id)
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { errors } = this.state

    return (
      <div>
        <Navbar />
        <div className='register'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 m-auto'>
                <h1 className='display-4 text-center'>Edit this Department</h1>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <label>Title</label>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.title
                      })}
                      placeholder='Title'
                      name='title'
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className='invalid-feedback'>{errors.title}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Description</label>
                    <textarea
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.description
                      })}
                      placeholder='Description'
                      name='description'
                      value={this.state.description}
                      onChange={this.onChange}
                    />
                    {errors.description && (
                      <div className='invalid-feedback'>
                        {errors.description}
                      </div>
                    )}
                  </div>
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditDepartment.propTypes = {
  updateDepartment: PropTypes.func.isRequired,
  getDepartment: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  department: PropTypes.object
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  department: state.departmentsData.department
})

export default connect(
  mapStateToProps,
  {
    getDepartment,
    updateDepartment
  }
)(withRouter(EditDepartment))
