import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { changePassword } from '../../actions/authActions'
import { Link } from "react-router-dom";
import Navbar from "../layout/Navbar";

class ChangePassword extends Component {
    constructor () {
        super()
        this.state = {
          old_password: '',
          new_password: '',
          confirm_password: '',
          error_message: '',
        }
    
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
      }
    componentDidMount() {

    }
    onSubmit(e) {
        e.preventDefault()
        this.setState({error_message: ''});

        if(this.state.old_password === "") {
            this.setState({error_message: 'Old password cannot be empty'});
            return;
        }

        if(this.state.new_password === "") {
            this.setState({error_message: 'New password cannot be empty'});
            return;
        }

        if(this.state.confirm_password === "") {
            this.setState({error_message: 'Confirm password cannot be empty'});
            return;
        }

        if(this.state.new_password == this.state.confirm_password) {
            this.props.changePassword({
                old_password: this.state.old_password,
                new_password: this.state.new_password
            }, this.props.history);
        } else {
            this.setState({error_message: 'New and Confirm Password is not matching !'});
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="profiles">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="display-5 text-center">Change Password</h3>
                                <p className="lead text-center">You can Change admin password from here</p>
                                <div className="col-md-4">
                                    <form onSubmit={this.onSubmit}>
                                        <div className='form-group'>
                                            <input
                                                type='password'
                                                className={classnames('form-control')}
                                                placeholder='Old Password'
                                                name='old_password'
                                                onChange={this.onChange}
                                            />
                                            <input
                                                type='password'
                                                className={classnames('form-control')}
                                                placeholder='New Password'
                                                name='new_password'
                                                onChange={this.onChange}
                                            />
                                            <input
                                                type='password'
                                                className={classnames('form-control')}
                                                placeholder='Confirm Password'
                                                name='confirm_password'
                                                onChange={this.onChange}
                                            />
                                            <br></br>
                                            <input
                                                type='submit'
                                                className={classnames('btn btn-primary')}
                                                placeholder='Confirm Password'
                                                name='confirm_password'
                                            />
                                            <p className='is-invalid'>{this.props.errors.message}</p>
                                            <p className='is-invalid'>{this.state.error_message}</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    errors: PropTypes.object
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { changePassword }
)(withRouter(ChangePassword));
