import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Category extends Component {
  render() {
    let link_path = "/careers";

    const departments = this.props.departments;
    const catergoryInfo = departments.map((dept, index) => {
      link_path = "/careers/" + dept._id;
      return (
        <div
          key={index + 1}
          className={`tab-pane fade ${index === 4 && "active show"}`}
          id={`jobCategoryTab${index}`}
          role="home-tab  "
          aria-labelledby="home-tab"
        >
          <div className="categoryTabsContentInner">
            <strong> {dept.title}</strong>
            <p>{dept.description}</p>

            <Link to={link_path}> Apply Now </Link>
          </div>
        </div>
      );
    });
    const catergoryLinks = departments.slice(0, 9).map((dept, index) => {
      return (
        <li key={index + 1} className="nav-item">
          <a
            className={`nav-link ${index === 4 && "active show"}`}
            id=""
            data-toggle="tab"
            href={`#jobCategoryTab${index}`}
            // href="#jobCategoryTab1"
            role="tab"
            aria-controls=""
            aria-selected="true"
          >
            <div className="catIcon">
              {/* c-management-icon */}
              {/* <img
                src={require(`../../../images/new/icon_${dept.title
                  .toLowerCase()
                  .split(" ")
                  .join("")}.jpg`)}
                alt="img"
              /> */}
              <img
                src={`${dept.image}`}
                alt="img"
              />
              {/* <img
                src={'https://img.icons8.com/ios-filled/2x/settings.png'}
                alt="img"
              /> */}
            </div>

            <strong> {dept.title} </strong>
          </a>
        </li>
      );
    });
    return (
      <div className="row" id="categorySection">
        <div
          className="col-lg-5 d-flex align-items-center categoryTabsContentSec wow slideInLeft"
          data-wow-duration="1500ms"
          data-wow-delay="800ms"
        >
          <div className="tab-content" id="">
            {catergoryInfo}
          </div>
        </div>
        <div
          className="col-lg-7 categoryTabsNavSec p-0 wow slideInRight"
          data-wow-duration="1500ms"
          data-wow-delay="800ms"
        >
          <ul className="nav categoryTabsNav" id="" role="tablist">
            {catergoryLinks}
          </ul>
        </div>
      </div>
    );
  }
}
