import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getTest, deleteTest } from '../../actions/testActions'
import { Link } from 'react-router-dom'
import isEmpty from '../../validation/is-empty'
import Spinner from '../common/Spinner'
import AddQuestions from '../questions/AddQuestion'
import { createQuestions } from '../../actions/questionActions'
import { getFormValues } from 'redux-form'
import Questions from '../questions/Questions'
import { getDepartments } from '../../actions/departmentActions'
import Navbar from '../layout/Navbar'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import _ from 'lodash'

class Test extends Component {
  // constructor() {
  //   super();
  // }

  componentDidMount () {
    localStorage.removeItem('testTime');
    localStorage.removeItem('totalQuestionsCount');
    if (this.props.match.params.id) {
      this.props.getTest(this.props.match.params.id)
    }
    this.props.getDepartments()
  }
  onDeleteClick (id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteTest(id)
            this.props.history.push('/tests')
          }
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    })
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.test) {
      this.setState({ test: nextProps.test })
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments })
    }
  }

  // Convert image to Base64 encoding.
  getBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  onQuestionsSubmit (e) {
    e.preventDefault()
    const values = this.props.values
    let questions = (values && values.questions) || {}
    console.log('redux-form submitted' + questions)
    const newQuestions = []
    let questionPromises = []
    const that = this
    if (!isEmpty(questions)) {
      questions.forEach(question => {
        if (!isEmpty(question.statement)) {
          let newQuestion = {
            statement: question.statement,
            typeOfQuestion: question.typeOfQuestion
          }
          if (_.isEmpty(question.image)) {
            that.getBase64(question.image).then(data => {
              newQuestion['image'] = data
            })
          }

          // add correct options
          if (!isEmpty(question.correctOptions)) {
            let correctOptions = []
            question.correctOptions.forEach(function (option) {
              if (!isEmpty(option)) {
                correctOptions.push({
                  option: option
                })
              }
            })
            newQuestion['correctOptions'] = correctOptions
          }

          // add wrong Options
          if (!isEmpty(question.wrongOptions)) {
            let wrongOptions = []
            question.wrongOptions.forEach(function (option) {
              if (!isEmpty(option)) {
                wrongOptions.push({
                  option: option
                })
              }
            })
            newQuestion['wrongOptions'] = wrongOptions
          }

          // newQuestions.append('question', newQuestion)
          questionPromises.push(Promise.resolve(newQuestion))
        }
      })
      Promise.all(questionPromises).then(function (newQuestions) {
        setTimeout(() => {
          that.props.createQuestions(
            newQuestions,
            that.props.match.params.id,
            that.props.history
          )
        }, 300)
      })
    }
    // send to server
  }

  previewTest(id) {
    localStorage.removeItem('testTime');
    localStorage.removeItem('totalQuestionsCount');
    this.props.history.push('/user_tests/new/test/1/' + id);
  }

  render () {
    const { test } = this.props
    const { departments } = this.props
    const testDepartment = departments
      .filter(department => department._id === test.department)
      .map(obj => obj.title)

    let testContent
    if (test == null) {
      testContent = <Spinner />
    } else {
      testContent = (
        <div className='container1'>
          <div className='card card-body bg-light mb-3'>
            <div className='row'>
              <div className='col-4'>
                <ul>
                  <li>
                    <strong>{test.title}</strong>
                  </li>
                  <li>
                    {test.status}{' '}
                    {isEmpty(test.department) ? null : (
                      <span>
                        at <i> {testDepartment}</i>
                      </span>
                    )}
                  </li>
                  <li>
                    {isEmpty(test.level) ? null : (
                      <span>
                        Level: <i>{test.level}</i>{' '}
                      </span>
                    )}
                  </li>
                  <li>
                    {isEmpty(test.time) ? null : (
                      <span>
                        Time: <i> {test.time} minutes</i>
                      </span>
                    )}
                  </li>
                  <li>
                    Questions to add: <i> {test.totalQuestions}</i>
                  </li>
                  <li>
                    Aptitude %: <i> {test.aptitudePercentage}</i>
                  </li>
                  <li>
                    Technical %: <i> {test.technicalPercentage}</i>
                  </li>
                </ul>
                {/* <span>
                  {test.status}{' '}
                  {isEmpty(test.department) ? null : (
                    <span>
                      at <i> {testDepartment}</i>
                    </span>
                  )}
                  {' For '}
                  {isEmpty(test.level) ? null : (
                    <span>
                      <i>{test.level}</i>{' '}
                    </span>
                  )}
                  {isEmpty(test.time) ? null : (
                    <span>
                      Time: <i> {test.time} minutes</i>
                    </span>
                  )}
                </span> */}
              </div>
              <div className='col-8'>
                <ul className='jobActions'>
                  <li>
                    <button
                      className='btn btn-default'
                      onClick={this.onDeleteClick.bind(this, test._id)}
                    >
                      Delete
                    </button>
                  </li>
                  <li>
                    <Link to={'/tests/edit/' + test._id}>
                      <button className='btn btn-default'>Edit</button>
                    </Link>
                  </li>
                  <li>
                    {/* <Link to={'/user_tests/new/test/1/' + test._id}> */}
                      <button 
                      className='btn btn-default'
                      onClick={this.previewTest.bind(this, test._id)}>Test Preview</button>
                    {/* </Link> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <AddQuestions handleSubmit={this.onQuestionsSubmit.bind(this)} />
          <Questions questions={test.questions} test_id={test._id} />
        </div>
      )
    }

    return (
      <div>
        <Navbar /> <div className='container'>{testContent}</div>
      </div>
    )
  }
}

Test.propTypes = {
  test: PropTypes.object.isRequired,
  getTest: PropTypes.func.isRequired,
  createQuestions: PropTypes.func.isRequired,
  values: PropTypes.object,
  getDepartments: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired
}
const mapStateToProps = state => ({
  test: state.testsData.test,
  values: getFormValues('AddQuestions')(state),
  departments: state.departmentsData.departments
})

export default connect(
  mapStateToProps,
  {
    getTest,
    deleteTest,
    createQuestions,
    getDepartments
  }
)(Test)
