import { Link } from "react-router-dom";

import React, { Component } from "react";

export default class ProfileHeader extends Component {
  render() {
    const steps = [
      "Personal Information <br/> / CV Upload",
      "Education/ Career <br/> History",
      // "Employment <br/> Questions",
      "Privacy <br/> Policy",
      "Review edit <br/> and Submit"
    ];
    // Index is monkey patched to increment , This was done to skip profile step 4 .
    const profile_steps = steps.map((step, index) => {
      let disabled = this.props.tab < index + 1;
      return (
        <li
          key={index + 1}
          className={
            `${this.props.tab == index + 1 && "active"} ` +
            `${this.props.tab >= index + 1 && "completed"} ` +
            `${disabled && ""}`
          }
        >
          {!disabled && (
            <Link
              to={`/profile-step-${
                [3, 4].indexOf(index + 1) >= 0 ? index + 2 : index + 1
              }`}
            >
              <span> {index + 1} </span>
              <strong>
                {step.split("<br/>")[0]} <br /> {step.split("<br/>")[1]}
              </strong>
            </Link>
          )}
          {disabled && (
            <Link to="#">
              <span> {index + 1} </span>
              <strong>
                {step.split("<br/>")[0]} <br /> {step.split("<br/>")[1]}
              </strong>
            </Link>
          )}
        </li>
      );
    });
    return (
      <div className="stepsNav clearfix">
        <div className="container">
          <ul>{profile_steps}</ul>
        </div>
      </div>
    );
  }
}
