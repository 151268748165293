import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createDepartment } from '../../actions/departmentActions';
import { connect } from 'react-redux';
import Navbar from '../layout/Navbar';

class CreateDepartment extends Component {
	constructor() {
		super();
		this.state = {
			title: '',
			description: '',
			picture: '',
			imagePreviewUrl: '',
			errors: {}
		};
		this.picture = React.createRef();
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(e) {
		e.preventDefault();
		if (this.state.picture == null || this.state.picture == undefined) {
			alert('Please upload the Picture before proceeding.');
		} else {
			const newDepartment = new FormData();
			newDepartment.append('title', this.state.title);
			newDepartment.append('description', this.state.description);
			newDepartment.append('picture', this.state.picture);
			// const newDepartment =  {
			//   	title: this.state.title,
			//     description: this.state.description,
			//     picture: this.state.picture
      //   };
			this.props.createDepartment(newDepartment, this.props.history);
		}
	}

	_handleImageChange(e) {
		e.preventDefault();

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			this.setState({
				picture: file,
				imagePreviewUrl: reader.result
			});
		};

		reader.readAsDataURL(file);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({ errors: nextProps.errors });
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	removePicture(e) {
		this.setState({ picture: '', imagePreviewUrl: '' });
	}

	render() {
		const { errors } = this.state;
		let { imagePreviewUrl } = this.state;
		let $imagePreview = null;
		if (imagePreviewUrl || this.state.avatar) {
			let $imageUrl = this.state.avatar;
			if (imagePreviewUrl) $imageUrl = imagePreviewUrl;
			$imagePreview = <img src={$imageUrl} />;
		} else {
			$imagePreview = (
				<img
					src={require('../../images/default.png')}
					height="200"
					ref={(ref) => (this.picPreview = ref)}
					alt="Image preview..."
				/>
			);
		}

		return (
			<div>
				<Navbar />
				<div className="register">
					<div className="container">
						<div className="row">
							<div className="col-md-8 m-auto">
								<h1 className="display-4 text-center">Add a Department</h1>
								<p className="lead text-center">Create a Department.</p>
								<form noValidate onSubmit={this.onSubmit} encType="multipart/form-data">
									<div className="form-group">
										<label>Title</label>
										<input
											type="text"
											className={classnames('form-control form-control-lg', {
												'is-invalid': errors.title
											})}
											placeholder="Title"
											name="title"
											value={this.state.title}
											onChange={this.onChange}
										/>
										{errors.title && <div className="invalid-feedback">{errors.title}</div>}
									</div>
									<div className="form-group">
										<label>Description</label>
										<textarea
											type="text"
											className={classnames('form-control form-control-lg', {
												'is-invalid': errors.description
											})}
											placeholder="Description"
											name="description"
											value={this.state.description}
											onChange={this.onChange}
										/>
										{errors.description && (
											<div className="invalid-feedback">{errors.description}</div>
										)}
									</div>
									<div className="loginForm">
											<div className="col-md-12" />
											<div className="profilePicInput">
												<strong>{$imagePreview}</strong>
												<input
													className="form-control"
													type="file"
													name="picture"
													ref={(ref) => (this.picture = ref)}
													onChange={(e) => this._handleImageChange(e)}
												/>
											</div>
											<div className="profilePicDetails">
												<p>
													Maximum Size: 1.5 MB <br /> Allowed Types : jpg, png
												</p>
												<a onClick={this.removePicture.bind(this)}>
													<i className="far fa-trash-alt" /> Remove Picture
												</a>
											</div>
										</div>
									<button type="submit" className="btn btn-info btn-block mt-4">
										Submit
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateDepartment.propTypes = {
	createDepartment: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(mapStateToProps, { createDepartment })(withRouter(CreateDepartment));
