import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from "react-alert-template-basic";

class AlertTemplate extends Component {
  render () {
    // the style contains only the margin given as offset
    // options contains all alert given options
    // message is the alert message...
    // close is a function that closes the alert
    const { style, options, message, close } = this.props

    return (
      <div
        className=' alert alert-success alert-dismissible'
        style={{ 'margin-top': '90px' }}
      >
        <a href='#' className='close' data-dismiss='alert' aria-label='close'>
          &times;
        </a>
        {message}
      </div>
    )
  }
}

const options = {
  position: 'top center',
  timeout: 5000,
  offset: '30px',
  transition: 'scale',
  type: 'success'
}
ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
