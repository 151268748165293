import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { resetPassword } from '../../actions/authActions'
import { connect } from 'react-redux'
import NavLinks from '../layout/NavLinks'
import { withAlert } from 'react-alert'

class ResetPassword extends Component {
  constructor () {
    super()
    this.state = {
      password: '',
      confirm: '',
      token: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (e) {
    e.preventDefault()

    const resetPassword = {
      password: this.state.password,
      confirm: this.state.confirm,
      token: this.state.token
    }
    this.props.resetPassword(
      resetPassword,
      this.props.history,
      this.props.alert
    )
  }

  componentDidMount () {
    if (this.props.match.params.token !== '') {
      this.setState({ token: this.props.match.params.token })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { errors } = this.state

    return (
      <div>
        <div className='loginSection'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-5 col-md-10'>
                <div className='loginLogo'>
                  <a href='/'>
                    <img
                      src={require('../../images/site-logo.svg')}
                      alt='NRP'
                    />
                  </a>
                </div>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <input
                      type='password'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.password
                      })}
                      placeholder='Password'
                      name='password'
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className='invalid-feedback'>{errors.password}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <input
                      type='password'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.confirm
                      })}
                      placeholder='Confirm'
                      name='confirm'
                      value={this.state.confirm}
                      onChange={this.onChange}
                    />
                    {errors.confirm && (
                      <div className='invalid-feedback'>{errors.confirm}</div>
                    )}
                  </div>
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Reset password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  errors: state.errors.data
})

export default connect(
  mapStateToProps,
  { resetPassword }
)(withAlert(ResetPassword))
