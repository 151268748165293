import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { updateCompany, getCompany } from '../../actions/companyActions'
import { connect } from 'react-redux'
import Navbar from '../layout/Navbar'
class EditCompany extends Component {
  constructor () {
    super()
    this.state = {
      title: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (e) {
    e.preventDefault()

    const editCompany = {
      title: this.state.title
    }

    this.props.updateCompany(
      this.props.match.params.id,
      editCompany,
      this.props.history
    )
  }

  componentDidMount () {
    this.props.getCompany(this.props.match.params.id)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
    if (nextProps.company) {
      const company = nextProps.company
      // Set Component State Field
      this.setState({
        title: company.title
      })
    }
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { errors } = this.state

    return (
      <div>
        <Navbar />
        <div className='register'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 m-auto'>
                <h1 className='display-4 text-center'>Edit this Company</h1>
                <p className='lead text-center'>Edit this Company.</p>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <label>Title</label>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.title
                      })}
                      placeholder='Title'
                      name='title'
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className='invalid-feedback'>{errors.title}</div>
                    )}
                  </div>
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EditCompany.propTypes = {
  updateCompany: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  company: PropTypes.object
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  company: state.companiesData.company
})

export default connect(
  mapStateToProps,
  { getCompany, updateCompany }
)(withRouter(EditCompany))
