import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { confirmUser } from "../../actions/authActions";
import { Link } from "react-router-dom";

class Confirm extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/careers");
    } else {
      this.props.confirmUser(this.props.match.params.uuid, this.props.history);
    }
  }

  render() {
    return <div>Confirm Email Page</div>;
  }
}

Confirm.propTypes = {
  confirmUser: PropTypes.func.isRequired,
  auth: PropTypes.object,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { confirmUser }
)(withRouter(Confirm));
