import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";
import isEmpty from "../../validation/is-empty";
import { debug } from "util";

const renderField = ({
  input,
  placeholder,
  type,
  className,
  meta: { touched, error },
}) => (
  <div>
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className={className}
    />
    {touched && error && <span>{error}</span>}
  </div>
);

const renderEducation = ({
  fields,
  errors,
  educations,
  range,
  meta: { error, submitFailed },
}) => (
  <div>
    {submitFailed && error && <span>{error}</span>}
    <ul>
      {fields.map((education, index) => (
        <li key={index}>
          <div className="row educationFielsSection">
            <div className="col-sm-6">
              <div className="form-group">
                <Field
                  name={`${education}.institute`}
                  className="form-control"
                  type="text"
                  placeholder="* College/ University"
                  component={renderField}
                />
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].institute}</div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Field
                  name={`${education}.degree`}
                  className="form-control"
                  type="text"
                  placeholder="* Degree"
                  component="select"
                >
                  <option value="" disabled>
                    Please select degree
                  </option>
                  {educations &&
                    educations.map((e) => {
                      return <option>{e.title}</option>;
                    })}
                </Field>
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].degree}</div>
                )}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <Field
                  name={`${education}.fieldOfStudy`}
                  className="form-control"
                  type="text"
                  placeholder="* Specialization"
                  component={renderField}
                />
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].fieldOfStudy}</div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Field
                  name={`${education}.cgpa`}
                  className="form-control"
                  type="number"
                  placeholder=" CGPA"
                  component={renderField}
                />
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].cgpa}</div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Field
                  name={`${education}.percentage`}
                  className="form-control"
                  type="number"
                  placeholder="Percentage"
                  component={renderField}
                />
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].percentage}</div>
                )}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <label className="input-group-text">From</label>
                </div>
                <Field
                  name={`${education}.from`}
                  className="custom-select"
                  type="select"
                  component="select"
                >
                  {range &&
                    range.map((year, index) => {
                      return <option key={index + 1}>{year}</option>;
                    })}
                </Field>
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].from}</div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <label className="input-group-text">To</label>
                </div>
                <Field
                  name={`${education}.to`}
                  className="custom-select"
                  type="select"
                  component="select"
                >
                  {range &&
                    range.map((year, index) => {
                      return <option key={index + 1}>{year}</option>;
                    })}
                </Field>
                {errors && errors[index] && (
                  <div className="is-invalid">{errors[index].to}</div>
                )}
              </div>
            </div>
            <div className="addMoreFieldGroup col-sm-12">
              {index !== 0 && (
                <button
                  onClick={() => fields.remove(index)}
                  style={{ float: "right" }}
                >
                  <i className="far fa-trash-alt" />
                </button>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
    <div className="addMoreFieldGroup">
      <button
        type="button"
        onClick={() => {
          fields.push({});
        }}
      >
        <i className="fas fa-plus" /> Add Education
      </button>
    </div>
  </div>
);

class AddEducation extends Component {
  componentWillReceiveProps(nextProps) {
    this.setState({ initialValues: nextProps.initialValues });
  }
  render() {
    const { errors, range } = this.props;
    const { educations } = this.props;
    return (
      <div>
        <FieldArray
          name="education"
          errors={errors}
          range={range}
          educations={educations}
          component={renderEducation}
        />
      </div>
    );
  }
}

AddEducation = reduxForm({
  form: "AddEducation",
  enableReinitialize: true,
})(AddEducation);

AddEducation.propTypes = {
  initialValues: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  educations: state.educationsData.educations,
  initialValues: !isEmpty(state.auth.profile.education)
    ? state.auth.profile
    : { education: [{}] }, // pull initial values from account reducer
});

export default connect(mapStateToProps)(AddEducation);