import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
// import classNames from 'classnames'

// import Dropzone from 'react-dropzone'

const renderField = ({
  input,
  label,
  type,
  className,
  meta: { touched, error }
}) => (
  <div className='mt-3'>
    <label>{label}</label> <br />
    <div>
      <input {...input} type={type} placeholder={label} className={className} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
)

const renderWrongOptions = ({ fields, meta: { error } }) => (
  <ul>
    <li>
      <button
        type='button'
        className='btn btn-secondary btn-sm'
        onClick={() => fields.push()}
      >
        Add Wrong Options
      </button>
    </li>
    {fields.map((option, index) => (
      <li key={index}>
        <button
          className=' secClose'
          type='button'
          aria-label='Close'
          title='Remove Option'
          onClick={() => fields.remove(index)}
        >
          <span aria-hidden='true'>
            <i class='fas fa-trash-alt' />
          </span>
        </button>

        <Field
          name={option}
          className='form-control'
          type='text'
          component={renderField}
          label={`Option #${index + 1}`}
        />
      </li>
    ))}
    {error && <li className='error'>{error}</li>}
  </ul>
)

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0])

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => {
  return (
    <input
      onChange={adaptFileEventToValue(onChange)}
      onBlur={adaptFileEventToValue(onBlur)}
      type='file'
      {...props.input}
      {...props}
    />
  )
}

const renderCorrectOptions = ({ fields, meta: { error } }) => (
  <ul>
    <li>
      <button
        type='button'
        className='btn btn-info btn-sm'
        onClick={() => fields.push()}
      >
        Add Correct Options
      </button>
    </li>
    {fields.map((option, index) => (
      <li key={index}>
        <button
          type='button'
          className='secClose'
          title='Remove Option'
          onClick={() => fields.remove(index)}
        >
          <span aria-hidden='true'>
            <i className='fas fa-trash-alt' />
          </span>
        </button>
        <Field
          name={option}
          type='text'
          className='form-control'
          component={renderField}
          label={`Option #${index + 1}`}
        />
      </li>
    ))}
    {error && <li className='error'>{error}</li>}
  </ul>
)
const renderQuestions = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    {submitFailed && error && <span>{error}</span>}
    <ul className='addQuestionSection'>
      {fields.map((question, index) => (
        <li key={index}>
          <button
            type='button'
            className='secClose'
            onClick={() => fields.remove(index)}
          >
            <span aria-hidden='true'>
              <i className='fas fa-trash-alt' />
            </span>
          </button>
          <h4>Question #{index + 1}</h4>
          <div className='mb-4'>
            <Field
              name={`${question}.statement`}
              className='form-control'
              type='text'
              component={renderField}
              label='Statement'
            />
          </div>
          <div className='mb-4'>
            <Field
              name={`${question}.image`}
              type='file'
              component={FileInput}
              label='Image'
            />
          </div>
          <div className='mb-4'>
            <Field
              name={`${question}.typeOfQuestion`}
              component='select'
              className='form-control'
            >
              <option value='' disabled>
                Select Question Type
              </option>
              <option value='aptitude'>Aptitude</option>
              <option value='technical'>Technical</option>
            </Field>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <FieldArray
                name={`${question}.correctOptions`}
                component={renderCorrectOptions}
              />
            </div>
            <div className='col-md-6'>
              <FieldArray
                name={`${question}.wrongOptions`}
                component={renderWrongOptions}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
    <button
      type='button'
      className='btn btn-info mb-3 btn-sm'
      onClick={() => {
        fields.push({})
      }}
    >
      Add Question
    </button>
  </div>
)

const AddQuestions = props => {
  const { handleSubmit, pristine, reset, submitting } = props

  return (
    <form
      onSubmit={handleSubmit}
      name='AddQuestions'
      encrypt='multipart/form-data'
    >
      <FieldArray name='questions' component={renderQuestions} />
      <div className='row'>
        <div className='col-12'>
          <button type='submit' className='btn btn-info' disabled={submitting}>
            Submit
          </button>
          <button
            type='button'
            className='btn btn-secondary ml-1'
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear
          </button>
        </div>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'AddQuestions'
})(AddQuestions)
