import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEmpty from '../../validation/is-empty'
import { connect } from 'react-redux'
import { deleteJob, getJob, changeStatus } from '../../actions/jobActions'
import { getPublicDepartments } from '../../actions/departmentActions'
import { getCompanies } from '../../actions/companyActions'
import { getTests } from '../../actions/testActions'
import { Link } from 'react-router-dom'
import Spinner from '../common/Spinner'
import Applicants from './Applicants'
import Navbar from '../layout/Navbar'
import moment from 'moment'
import { MOMENT_FORMAT } from '../../globals/variables'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { withAlert } from 'react-alert'

class Job extends Component {
  constructor () {
    super()
    this.state = {
      job: []
    }
  }
  onDeleteClick (id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteJob(id)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    })
  }

  componentDidMount () {
    if (this.props.match.params.id) {
      this.props.getJob(this.props.match.params.id)
      this.props.getCompanies()
    }
    if (this.props.match.params.id) {
      this.props.getJob(this.props.match.params.id)
    }
    this.props.getPublicDepartments()
    this.props.getTests()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.job) {
      this.setState({ job: nextProps.job })
      if (
        !isEmpty(nextProps.job) &&
        !isEmpty(this.state.job && this.state.job.status) &&
        this.state.job.status !== nextProps.job.status
      ) {
        this.props.alert.show(`Job status is ${nextProps.job.status} now.`)
      }
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments })
    }
    if (nextProps.tests) {
      this.setState({ tests: nextProps.tests })
    }
  }
  changeStatus (status) {
    if (this.props.match.params.id) {
      this.props.changeStatus(this.props.match.params.id, status)
    }
  }

  render () {
    // const { job } = this.state;
    const { job, applicants } = this.props
    const { companies } = this.props
    const company = companies.filter(c => c._id == job.company)
    let jobContent
    const { departments } = this.props
    const { tests } = this.props
    const jobDepartment = departments
      .filter(department => department._id === job.department)
      .map(obj => obj.title)
    const test = tests
      .filter(test => test._id === job.test)
      .map(obj => obj.title)

    if (job == null) {
      jobContent = <Spinner />
    } else {
      jobContent = (
        <div className='card card-body bg-light mb-3'>
          <div className='row mb-4'>
            <div className='col-12'>
              <ul className='jobActions'>
                <li>
                  <button
                    className='btn btn-default'
                    onClick={this.onDeleteClick.bind(this, job._id)}
                  >
                    Delete
                  </button>
                </li>
                <li>
                  <Link
                    className='btn btn-default'
                    to={'/jobs/edit/' + job._id}
                  >
                    Edit
                  </Link>
                </li>
                <li>
                  <button
                    className='btn btn-default'
                    onClick={this.changeStatus.bind(this, 'archived')}
                  >
                    Archive
                  </button>
                </li>
                <li>
                  <button
                    className='btn btn-default'
                    onClick={this.changeStatus.bind(this, 'closed')}
                  >
                    Close
                  </button>
                </li>
                <li>
                  <button
                    className='btn btn-default'
                    onClick={this.changeStatus.bind(this, 'open')}
                  >
                    Open
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3>{job.title}</h3>

              <div className='row'>
                <div className='col-md-6'>
                  <p>
                    {/* {job.status} */}
                    {isEmpty(company) ? null : (
                      <span>at {company[0] && company[0].title}</span>
                    )}
                  </p>
                  <p className='fixRenderHtml'>
                    {isEmpty(job.description) ? null : (
                      <span>
                        {' '}
                        <div
                          dangerouslySetInnerHTML={{ __html: job.description }}
                        />
                        {/* <strong>Job Description:</strong> {job.description} */}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.cgpa) ? null : (
                      <span>
                        <strong>CGPA: </strong>
                        {job.cgpa}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.experience) ? null : (
                      <span>
                        <strong>Experience: </strong>
                        {job.experience} years
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.career_level) ? null : (
                      <span>
                        <strong>Career Level: </strong> {job.career_level}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.job_type) ? null : (
                      <span>
                        <strong>Job Type: </strong> {job.job_type}
                      </span>
                    )}
                  </p>
                </div>
                <div className='col-md-6'>
                  <p>
                    {isEmpty(job.start_date) ? null : (
                      <span>
                        <strong>Start Date: </strong>{' '}
                        {moment(job.start_date).format(MOMENT_FORMAT)}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.end_date) ? null : (
                      <span>
                        <strong>End Date: </strong>
                        {moment(job.end_date).format(MOMENT_FORMAT)}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.test) ? null : (
                      <span>
                        <strong>Test: </strong> {test}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.department) ? null : (
                      <span>
                        <strong>Department: </strong>
                        {jobDepartment}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.location) ? null : (
                      <span>
                        <strong>Location: </strong> {job.location}
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.location) ? null : (
                      <span>
                        <strong>Status: </strong>
                        <span className='text-info'>{job.status}</span>
                      </span>
                    )}
                  </p>
                  <p>
                    {isEmpty(job.educations) ? null : (
                      <span>
                        <strong>Required Education: </strong>
                        <span className='text-primary'>
                          {job.educations.map(edu => edu.title + ' ')}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-md-6'>
                  <h4>Skill Set</h4>
                  <ul className='list-group'>
                    {/* {job.skills.slice(0, 4).map((skill, index) => (
                  <li key={index} className='list-group-item'>
                    <i className='fa fa-check pr-1' />
                    {skill}
                  </li>
                ))} */}
                    {job.skills}
                  </ul>
                </div>
                {/* <br /> */}
                <div className='col-md-6' />
              </div>
            </div>
          </div>

          <hr />
          <p>
            {isEmpty(job.employment_questions) ? null : (
              <span>
                <strong>Employment Questions: </strong>
                <span className='text-success'>
                  {job.employment_questions.map(q => {
                    return (
                      <div>
                        {q.title} <hr />
                      </div>
                    )
                  })}
                </span>
              </span>
            )}
          </p>
        </div>
      )
    }
    return (
      <div>
        <Navbar />
        <div className='container'>{jobContent}</div>
        <br />

        <Applicants applicants={applicants} job={job} />
      </div>
    )
  }
}

Job.propTypes = {
  job: PropTypes.object.isRequired,
  deleteJob: PropTypes.func.isRequired,
  getJob: PropTypes.func.isRequired,
  getPublicDepartments: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  getTests: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  applicants: PropTypes.array,
  changeStatus: PropTypes.func.isRequired,
  getCompanies: PropTypes.func
}

const mapStateToProps = state => ({
  job: state.jobsData.job,
  applicants: state.jobsData.applicants,
  departments: state.departmentsData.departments,
  tests: state.testsData.tests,
  companies: state.companiesData.companies
})

export default connect(
  mapStateToProps,
  {
    deleteJob,
    getJob,
    getPublicDepartments,
    getTests,
    changeStatus,
    getCompanies
  }
)(withAlert(Job))
