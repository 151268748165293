import axios from 'axios'
import { reset } from 'redux-form'
import { GET_ERRORS, UPDATE_TEST } from './types'

// Create Bulk Questions
export const createQuestions = (
  questionsData,
  test_id,
  history
) => dispatch => {
  axios
    .post(`/api/admin/tests/${test_id}/questions/create`, questionsData)
    .then(res => {
      dispatch({
        type: UPDATE_TEST,
        payload: res.data
      })
      dispatch(reset('AddQuestions'))
      history.push('/tests/show/' + test_id)
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Delete a question by id
export const deleteQuestion = (test_id, id) => dispatch => {
  // some axios shit here
  axios
    .delete(`/api/admin/tests/${test_id}/question/delete/${id}`)
    .then(res => {
      dispatch({
        type: UPDATE_TEST,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response
      })
    )
}
