import React, { Component } from "react";
import Search from "./Search";
import InstagramSection from "../layout/landing/InstagramSection";
import JobPost from "./JobPost";
// import SearchFilters from "./SearchFilters";
import {
  getJobs,
  getDepartmentJobs,
  getAvailableJobs
} from "../../actions/jobActions";
import { getPublicDepartments } from "../../actions/departmentActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import isEmpty from "../../validation/is-empty";
import _ from "lodash";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { CAREER_PAGINATION_PAGE_SIZE } from "../../globals/variables";
class Careers extends Component {
  constructor() {
    super();
    this.state = {
      jobs: [],
      user: "",
      filteredJobs: [],
      departmentName: "",
      selectedJobTypeFilters: [],
      selectedDepartmentFilters: [],
      paginatedJobs: [],
      datePostedFilter: "",
      pageCount: 0,
      perPage: CAREER_PAGINATION_PAGE_SIZE,
      offset: 0,
      searchByTitle: "",
      searchByLocation: ""
    };
  }
  componentDidMount() {
    if (isEmpty(this.props.match.params.id)) {
      this.props.getAvailableJobs();
    } else {
      this.props.getDepartmentJobs(this.props.match.params.id);
      this.props.getPublicDepartments(this.props.match.params.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jobs) {
      //const a = _.take(_.drop(items, skipCount), takeCount);
      this.setState({
        jobs: nextProps.jobs,
        filteredJobs: nextProps.jobs,
        paginatedJobs: _.take(
          _.drop(nextProps.jobs, this.state.perPage * this.state.offset),
          this.state.perPage
        ),
        pageCount: Math.ceil(nextProps.jobs.length / this.state.perPage) // data.meta.limit
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filteredJobs.length != prevState.filteredJobs.length) {
      this.setState({
        paginatedJobs: _.take(
          _.drop(
            this.state.filteredJobs,
            this.state.perPage * this.state.offset
          ),
          this.state.perPage
        ),
        offset: 0,
        pageCount: Math.ceil(
          this.state.filteredJobs.length / this.state.perPage
        ) // data.meta.limit
      });
    }
  }

  jobTypeFilter(e) {
    if (e.target.checked) {
      this.state.selectedJobTypeFilters.push(e.target.value);
    } else {
      this.state.selectedJobTypeFilters.splice(
        this.state.selectedJobTypeFilters.indexOf(e.target.value),
        1
      );
    }
    this.search();
  }

  departmentFilter(e) {
    if (e.target.checked) {
      this.state.selectedDepartmentFilters.push(e.target.value);
    } else {
      this.state.selectedDepartmentFilters.splice(
        this.state.selectedDepartmentFilters.indexOf(e.target.value),
        1
      );
    }
    this.search();
  }

  dateFilter(e) {
    this.state.datePostedFilter = e.target.value;
    this.search();
  }

  onSearchFilterChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim().toLowerCase() });
  }

  // set paginatedJob State after click event on pagination bar
  handlePageClick(e) {
    this.setState({ offset: e.selected });
    this.state.paginatedJobs = _.take(
      _.drop(this.state.filteredJobs, this.state.perPage * e.selected),
      this.state.perPage
    );
  }

  // resets pagination and gt to page one after filtering data
  resetPagination() {
    // this.state.paginatedJobs =
    this.setState({
      paginatedJobs: _.take(
        _.drop(this.state.filteredJobs, this.state.perPage * this.state.offset),
        this.state.perPage
      ),
      offset: 0
    });
  }

  // Following is used to search by title or location
  // searchPanel(e) {
  // }

  // General Search Function
  search() {
    let filteredJobs = this.state.jobs;
    let datePosted = this.state.datePostedFilter;
    var timeFilterValue = "";
    switch (datePosted) {
      case "Last hour": {
        timeFilterValue = moment().subtract(1, "hours");
        break;
      }
      case "Last 24 hours": {
        timeFilterValue = moment().subtract(24, "hours");
        break;
      }
      case "Last 14 days": {
        timeFilterValue = moment().subtract(14, "days");
        break;
      }
      case "Last 7 days": {
        timeFilterValue = moment().subtract(7, "days");
        break;
      }
      case "Last 30 days": {
        timeFilterValue = moment().subtract(14, "days");
        break;
      }
      case "All": {
        timeFilterValue = undefined;
        break;
      }
      default: {
        // statements;
        break;
      }
    }
    if (timeFilterValue) {
      // Convert PKT  to UTC
      timeFilterValue = timeFilterValue.subtract(5, "hours").format();
      // -------
      filteredJobs = this.state.jobs.filter(j => j.date > timeFilterValue);
    }
    if (this.state.selectedJobTypeFilters.length > 0) {
      filteredJobs = filteredJobs.filter(
        j => this.state.selectedJobTypeFilters.indexOf(j.job_type) >= 0
      );
    }
    if (this.state.selectedDepartmentFilters.length > 0) {
      filteredJobs = filteredJobs.filter(
        j =>
          this.state.selectedDepartmentFilters.indexOf(j.department.title) >= 0
      );
    }
    if (this.state.searchByTitle && this.state.searchByTitle) {
      filteredJobs = filteredJobs.filter(j =>
        j.title
          .trim()
          .toLowerCase()
          .includes(this.state.searchByTitle.trim().toLowerCase())
      );
    }
    if (this.state.searchByLocation && this.state.searchByLocation) {
      filteredJobs = filteredJobs.filter(j =>
        j.location
          .trim()
          .toLowerCase()
          .includes(this.state.searchByLocation.trim().toLowerCase())
      );
    }

    this.setFilteredJobs(filteredJobs);
    // this.setState({ filteredJobs: filteredJobs });
  }

  sortOrder(e) {
    this.setState({ filteredJobs: this.state.filteredJobs.reverse() });
    this.resetPagination();
  }

  //This method sort jobs by date and set state.
  //This is important for sortby filter as it will not work otherwise
  setFilteredJobs(setFilteredJobs) {
    let sortedFilteredJobs = _.sortBy(setFilteredJobs, function(o) {
      return new moment(o.date);
    });
    this.setState({ filteredJobs: sortedFilteredJobs });
    this.resetPagination();
  }
  render() {
    const {
      filteredJobs,
      paginatedJobs,
      jobs,
      departments,
      perPage,
      offset
    } = this.state;
    const { user } = this.props;
    let uniqDepartmentsList = _.uniq(jobs.map(j => j.department.title));
    let job_types = _.uniq(jobs.map(j => j.job_type));
    const jobType = (
      <div className="filterInputRadio">
        {job_types.map(type => {
          return (
            <div className="form-check">
              <label>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  value={type}
                  onChange={this.jobTypeFilter.bind(this)}
                />
                {type} ({jobs.filter(j => j.job_type == type).length})
              </label>
            </div>
          );
        })}
      </div>
    );

    const uniqDepartments = (
      <select
        className="form-control form-control-sm"
        onChange={this.departmentFilter.bind(this)}
      >
        {uniqDepartmentsList &&
          uniqDepartmentsList.map(j => {
            return <option>{j}</option>;
          })}
      </select>
    );
    return (
      <div>
        <Navbar />
        {/* <Search cc="====" /> */}
        <div className="innerPageBannerWrap">
          <div
            className="innerPageBanner"
            style={{
              backgroundImage: `url(${require("../../images/job-listing-banner.jpg")})`
            }}
          >
            <div className="bannerContainer container">
              <div className="bannerContent">
                <div className="jobSearchSection">
                  <strong> Career Opportunities </strong>
                  <div className="jobSearchForm">
                    <div className="row">
                      <div className="col-md-7">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control keyword"
                            placeholder="Keywords (e.g. Job Title)"
                            name="searchByTitle"
                            onChange={this.onSearchFilterChange.bind(this)}
                            value={this.state.searchByTitle}
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control location"
                            placeholder="Location"
                            name="searchByLocation"
                            onChange={this.onSearchFilterChange.bind(this)}
                            value={this.state.searchByLocation}
                          />
                        </div>
                      </div>
                      <input
                        type="search"
                        className="btn"
                        value="Search"
                        onClick={this.search.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="innerPageContentWrap">
          <div className="innerPageContentContainer container">
            <div className="row">
              <div className="col-lg-3 col-md-4" />
              <div className="col-lg-9 col-md-8">
                <h2>
                  {this.props.match.params.id &&
                    !isEmpty(departments) &&
                    departments.filter(
                      dp => dp._id == this.props.match.params.id
                    )[0].title}
                </h2>
                <div className="row jobListSecTop">
                  {jobs && (
                    <div className="col-lg-5">
                      <h2>
                        Job Openings {perPage * offset + 1} -
                        {perPage * offset + paginatedJobs.length} of{" "}
                        {filteredJobs.length}
                      </h2>
                    </div>
                  )}

                  <div className="col-lg-7">
                    <div className="row sortFilter">
                      <div className="col-sm-6">
                        <div className="form-group row">
                          <label className="col-4 p-0">Sort By:</label>
                          <div className="col-8">
                            <select
                              className="form-control form-control-sm"
                              onChange={this.sortOrder.bind(this)}
                            >
                              {/* <option>Relevancy</option> */}
                              <option>Newest</option>
                              <option>Oldest</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-6">
                        <div className="form-group row">
                          <label className="col-5 p-0">Jobs Available:</label>
                          <div className="col-7">{uniqDepartments}</div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filterOpenBtn">
              <a className="siteBtnLight" href="#">
                <i className="fas fa-filter" /> Filter
              </a>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 sideFilterWrap">
                <div className="sidefilterBox sortFilterMobile">
                  <h3
                    data-toggle="collapse"
                    href="#filterZero"
                    role="button"
                    aria-expanded="false"
                    aria-controls="filterZero"
                  >
                    Sort By
                    <i className="fas fa-minus" />
                    <i className="fas fa-plus" />
                  </h3>

                  <div className="collapse show" id="filterZero">
                    <div className="form-group">
                      onChange={this.sortOrder.bind(this)}
                      <select className="form-control">
                        {/* <option>Relevancy</option> */}
                        <option>Newest</option>
                        <option>Oldest</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label> Jobs Available: </label>
                      {uniqDepartments}
                    </div>
                  </div>
                </div>

                <div className="sidefilterBox">
                  <h3
                    data-toggle="collapse"
                    href="#filterTwo"
                    role="button"
                    aria-expanded="false"
                    aria-controls="filterTwo"
                  >
                    Job Type
                    <i className="fas fa-minus" />
                    <i className="fas fa-plus" />
                  </h3>

                  <div className="collapse show" id="filterTwo">
                    {jobType}
                  </div>
                </div>

                <div className="sidefilterBox">
                  <h3
                    data-toggle="collapse"
                    href="#filterThree"
                    role="button"
                    aria-expanded="false"
                    aria-controls="filterThree"
                  >
                    Department
                    <i className="fas fa-minus" />
                    <i className="fas fa-plus" />
                  </h3>

                  <div className="collapse show" id="filterThree">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Departments"
                      />
                    </div>

                    <div className="filterInputRadio">
                      {uniqDepartmentsList &&
                        uniqDepartmentsList.map(d => {
                          return (
                            <div className="form-check">
                              <label>
                                <input
                                  type="checkbox"
                                  name=""
                                  id=""
                                  value={d}
                                  onChange={this.departmentFilter.bind(this)}
                                />
                                {d}(
                                {
                                  jobs.filter(j => j.department.title == d)
                                    .length
                                }
                                )
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="sidefilterBox">
                  <h3
                    data-toggle="collapse"
                    href="#filterOne"
                    role="button"
                    aria-expanded="false"
                    aria-controls="filterOne"
                  >
                    Date Posted
                    <i className="fas fa-minus" />
                    <i className="fas fa-plus" />
                  </h3>

                  <div className="collapse show" id="filterOne">
                    <div className="filterInputRadio">
                      <div className="form-check">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id=""
                            value="Last hour"
                            onChange={this.dateFilter.bind(this)}
                          />
                          Last hour
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="">
                          <input
                            type="radio"
                            name="exampleRadios"
                            id=""
                            value="Last 24 hours"
                            onChange={this.dateFilter.bind(this)}
                          />
                          Last 24 hours
                        </label>
                      </div>
                      <div className="form-check">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id=""
                            value="Last 14 days"
                            onChange={this.dateFilter.bind(this)}
                          />
                          Last 14 days
                        </label>
                      </div>
                      <div className="form-check">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id=""
                            onChange={this.dateFilter.bind(this)}
                            value="Last 7 days"
                          />
                          Last 7 days
                        </label>
                      </div>
                      <div className="form-check">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id=""
                            onChange={this.dateFilter.bind(this)}
                            value="Last 30 days"
                          />
                          Last 30 days
                        </label>
                      </div>
                      <div className="form-check">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id=""
                            onChange={this.dateFilter.bind(this)}
                            value="All"
                          />
                          All
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="jobListWrapper">
                  <div className="row">
                    {paginatedJobs &&
                      paginatedJobs.map(job => {
                        let applied = job.applicants.indexOf(user.id) >= 0;
                        return <JobPost job={job} applied={applied} />;
                      })}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="pagination">
                      {/* <ul> */}
                      {paginatedJobs && (
                        <ReactPaginate
                          previousLabel={<i className="fas fa-chevron-left" />}
                          nextLabel={<i className="fas fa-chevron-right" />}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.pageCount}
                          // marginPagesDisplayed={2}
                          // pageRangeDisplayed={4}
                          onPageChange={this.handlePageClick.bind(this)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.filteredJobs}
                        />
                      )}
                      {/* </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <InstagramSection />
        <Footer />
      </div>
    );
  }
}

Careers.propTypes = {
  getJobs: PropTypes.func.isRequired,
  getDepartmentJobs: PropTypes.func.isRequired,
  jobs: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  departments: PropTypes.array.isRequired,
  getAvailableJobs: PropTypes.func.isRequired
};

export default connect(
  state => ({
    jobs: state.jobsData.jobs,
    user: state.auth.user,
    departments: state.departmentsData.departments
  }),
  { getJobs, getDepartmentJobs, getPublicDepartments, getAvailableJobs }
)(Careers);
