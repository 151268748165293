import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getTests, deleteTest } from '../../actions/testActions'
import { getDepartments } from '../../actions/departmentActions'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import Navbar from '../layout/Navbar'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-notifications-component/dist/theme.css'
import { withAlert } from 'react-alert'

class Tests extends Component {
  // following is ES6 syntax to initialze constructor
  state = {
    tests: null,
    departments: [],
    flash: ''
  }

  componentDidMount () {
    this.props.getTests()
    this.props.getDepartments()
    if (this.props.flash !== '') {
      this.props.alert.show(this.props.flash)
    }
  }
  onDeleteClick (id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.deleteTest(id)
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    })
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.tests) {
      this.setState({ tests: nextProps.tests })
    }
    if (nextProps.departments) {
      this.setState({ departments: nextProps.departments })
    }
  }

  render () {
    const { loading, departments } = this.props

    const { tests } = this.state

    // const { departments } = this.state
    const columns = [
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Level',
        accessor: 'level'
      },
      {
        Header: 'Time(Mins)',
        accessor: 'time'
      },
      {
        Header: 'Questions',
        accessor: 'totalQuestions'
      },
      {
        Header: 'Aptitude %',
        accessor: 'aptitudePercentage'
      },
      {
        Header: 'Technical %',
        accessor: 'technicalPercentage'
      },
      {
        Header: 'Department',
        accessor: 'department',
        Cell: row => (
          <div>
            {departments
              .filter(department => department._id === row.value)
              .map(obj => obj.title)}
          </div>
        )
      },

      {
        Header: () => <span>Actions</span>,
        accessor: '_id',
        Cell: row => (
          <div>
            <i
              className='fa fa-trash-alt'
              aria-hidden='true'
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={'/tests/edit/' + row.value}>
              <i className='far fa-edit' title='Edit' />
              {/* <button className='btn btn-warning'>Edit</button> */}
            </Link>
            &nbsp;
            <Link to={'/tests/show/' + row.value}>
              <i className='fas fa-eye' />
            </Link>
          </div>
        )
      }
    ]

    // let testsListing;
    let data
    if (tests == null || loading) {
      // testsListing = <Spinner />;
    } else {
      if (tests.length > 0) {
        // testsListing = tests.map(test => <Test key={test._id} test={test} />);
        data = tests
      } else {
        // testsListing = <h4> No tests found...</h4>;
      }
    }
    return (
      <div>
        <Navbar />
        <div className='profiles'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='new-job'>
                  <Link to={`/create-test`} className='btn btn-info'>
                    New Test
                  </Link>
                </div>
                <h1 className='display-4 text-center'>Tests Listing</h1>
                <p className='lead text-center'>Browse and post tests</p>
                <ReactTable
                  data={data}
                  defaultPageSize={10}
                  columns={columns}
                  filterable
                />
                {/* {testsListing} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Tests.propTypes = {
  getTests: PropTypes.func.isRequired,
  getDepartments: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  tests: state.testsData.tests,
  departments: state.departmentsData.departments,
  flash: state.testsData.flash
})

export default connect(
  mapStateToProps,
  {
    getTests,
    deleteTest,
    getDepartments
  }
)(withAlert(Tests))
