import {
  GET_EDUCATION,
  GET_EDUCATIONS,
  DELETE_EDUCATION
} from '../actions/types'

const initialState = {
  education: {},
  educations: [],
  loading: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EDUCATION:
      return {
        ...state,
        education: action.payload
      }
    case GET_EDUCATIONS:
      return {
        ...state,
        educations: action.payload,
        loading: false
      }
    case DELETE_EDUCATION:
      return {
        ...state,
        // remove deleted education from state to update page
        educations: state.educations.filter(
          education => education._id !== action.payload.id
        ),
        loading: false
      }
    default:
      return state
  }
}
