import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authActions'

import { Provider } from 'react-redux'
import store from './store'
import 'react-table/react-table.css'

import Header from './components/layout/Header'
import Register from './components/auth/Register'
import Login from './components/auth/Login'
import ForgotPassword from './components/auth/ForgotPassword'
import ResetPassword from './components/auth/ResetPassword'
import Footer from './components/layout/Footer'
import Landing from './components/layout/Landing'
import Navbar from './components/layout/Navbar'
// import CreateJob from "./components/jobs/CreateJob";
import Dashboard from './components/layout/Dashboard'
import Jobs from './components/jobs/Jobs'

import './App.css'
import CreateJob from './components/jobs/CreateJob'

import EditJob from './components/jobs/EditJob'
import PrivateRoute from './components/common/PrivateRoute'
import AdminRoute from './components/common/AdminRoute'
import SideBar from './components/common/SideBar'

import CreateTest from './components/tests/CreateTest'
import CreateEmploymentQuestion from './components/employment_questions/CreateEmploymentQuestion'
import EmploymentQuestions from './components/employment_questions/EmploymentQuestions'
import EditEmploymentQuestion from './components/employment_questions/EditEmploymentQuestion'
import Test from './components/tests/Test'
import Tests from './components/tests/Tests'
import Job from './components/jobs/Job'
import EditTest from './components/tests/EditTest'
// Companies
import CreateCompany from './components/companies/CreateCompany'
import Companies from './components/companies/Companies'
import EditCompany from './components/companies/EditCompany'

import ProfileStepOne from './components/profile_steps/ProfileStepOne'
import ProfileStepTwo from './components/profile_steps/ProfileStepTwo'
import ProfileStepThree from './components/profile_steps/ProfileStepThree'
import ProfileStepFour from './components/profile_steps/ProfileStepFour'
import ProfileStepFive from './components/profile_steps/ProfileStepFive'

// Departments
import CreateDepartment from './components/departments/CreateDepartment'
import Departments from './components/departments/Departments'
import EditDepartment from './components/departments/EditDepartment'
import Careers from './components/careers/Careers'
import JobDetail from './components/careers/JobDetail'
import employmentQuestionsApply from './components/careers/EmploymentQuestions'
import ViewApplicant from './components/jobs/ViewApplicant'
// Educations

import EditEducation from './components/educations/EditEducation'
import CreateEducation from './components/educations/CreateEducation'
import Educations from './components/educations/Educations'

import ChangePassword from './components/change_password/ChangePassword';

// User giving tests | Modules
import CreateUserTest from './components/user_tests/CreateUserTest'
import UserTest from './components/careers/UserTest'
import Confirm from './components/auth/Confirm'
import Sidebar from './components/layout/Sidebar'
// check for token
if (localStorage.jwtToken) {
  // set Auth token header auth
  setAuthToken(localStorage.jwtToken)
  // Decode token and get user info exp
  const decode = jwt_decode(localStorage.jwtToken)
  // Set User and isAuthenticated
  store.dispatch(setCurrentUser(decode))

  // check for expired token
  const currentTime = Date.now() / 1000
  if (decode.exp < currentTime) {
    // logout the user
    store.dispatch(logoutUser())
    // TODO: clear current Profile

    // Redirect to login
    window.location.href = '/login'
  }
}

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <div className='App'>
            <Route exact path='/' component={Landing} />
            <div className='innerPageContentWrap1'>
              <div className='innerPageContentContainer1 container1'>
                <SideBar component={Sidebar} />
                <div className='mainWrapper'>
                  <Route exact path='/register' component={Register} />
                  <Route exact path='/login' component={Login} />
                  <Route
                    exact
                    path='/forgot-password'
                    component={ForgotPassword}
                  />
                  <Route exact path='/reset/:token' component={ResetPassword} />
                  <Route exact path='/confirm/:uuid' component={Confirm} />
                  <Route exact path='/careers' component={Careers} />
                  <Route exact path='/careers/:id' component={Careers} />
                  <Route exact path='/job-detail/:id' component={JobDetail} />
                  <Switch>
                    <PrivateRoute
                      exact
                      path='/user_tests/new/test/:job_id/:test_id/instructions'
                      component={UserTest}
                    />
                  </Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path='/jobs/apply/:id/employmentQuestions'
                      component={employmentQuestionsApply}
                    />
                  </Switch>
                  <Switch>
                    <PrivateRoute
                      exact
                      path='/user_tests/new/test/:job_id/:test_id'
                      component={CreateUserTest}
                    />
                  </Switch>
                </div>

                <Switch>
                  <AdminRoute exact path='/dashboard' component={Dashboard} />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/jobs' component={Jobs} />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/jobs/new' component={CreateJob} />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/jobs/edit/:id' component={EditJob} />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/jobs/show/:id' component={Job} />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/jobs/:id/applicant/:applicant_id'
                    component={ViewApplicant}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/create-test'
                    component={CreateTest}
                  />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/tests' component={Tests} />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/tests/edit/:id'
                    component={EditTest}
                  />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/tests/show/:id' component={Test} />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/employment_questions/new'
                    component={CreateEmploymentQuestion}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/employment_questions/edit/:id'
                    component={EditEmploymentQuestion}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/employment_questions'
                    component={EmploymentQuestions}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/companies/new'
                    component={CreateCompany}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/companies/edit/:id'
                    component={EditCompany}
                  />
                </Switch>
                <Switch>
                  <AdminRoute exact path='/companies' component={Companies} />
                </Switch>
                <Switch>
                  <PrivateRoute
                    exact
                    path='/profile-step-1'
                    component={ProfileStepOne}
                  />
                </Switch>
                <Switch>
                  <PrivateRoute
                    exact
                    path='/profile-step-2'
                    component={ProfileStepTwo}
                  />
                </Switch>
                {/* <Switch>
                  <PrivateRoute
                    exact
                    path='/profile-step-3'
                    component={ProfileStepThree}
                  />
                </Switch> */}
                <Switch>
                  <PrivateRoute
                    exact
                    path='/profile-step-4'
                    component={ProfileStepFour}
                  />
                </Switch>
                <Switch>
                  <PrivateRoute
                    exact
                    path='/profile-step-5'
                    component={ProfileStepFive}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/departments'
                    component={Departments}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/change_password'
                    component={ChangePassword}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/departments/new'
                    component={CreateDepartment}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/departments/edit/:id'
                    component={EditDepartment}
                  />
                </Switch>
                <Switch>
                  <AdminRoute
                    exact
                    path='/educations/new'
                    component={CreateEducation}
                  />
                </Switch>

                <Switch>
                  <AdminRoute
                    exact
                    path='/educations/edit/:id'
                    component={EditEducation}
                  />
                </Switch>

                <Switch>
                  <AdminRoute exact path='/educations' component={Educations} />
                </Switch>

                {/* <Switch> */}
                {/* <PrivateRoute exact path="/careers" component={Careers} /> */}
                {/* </Switch> */}
                {/* <Switch>
                  <PrivateRoute
                    exact
                    path="/job-detail/:id"
                    component={JobDetail}
                  />
                </Switch> */}
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App
