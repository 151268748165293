import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Job from './Job'
import { deleteJob, getJobs, hideMultipleJobs } from "../../actions/jobActions";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Navbar from "../layout/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-notifications-component/dist/theme.css";
import { withAlert } from "react-alert";
import moment from "moment";
import DatePicker from "react-datepicker";

const caseInsensitiveFilter = (filter, row) => {
  const filterValue = filter.value.toLowerCase();
  const rowValue = row[filter.id]
    ? row[filter.id].toString().toLowerCase()
    : "";

  // Check if the value is numeric and apply appropriate filtering
  return isNaN(row[filter.id])
    ? rowValue.includes(filterValue) // For text fields, use includes
    : rowValue.startsWith(filterValue); // For numeric fields, use startsWith
};

// const caseInsensitiveFilter = (filter, row) => {
//   const filterValue = filter.value.toLowerCase();
//   const rowValue = row._original.title.toLowerCase();
//   return rowValue.includes(filterValue);
// };
class Jobs extends Component {
  // following is ES6 syntax to initialze constructor
  state = {
    jobs: null,
    flash: "",
    selectedRows: [], // Array to keep track of selected rows
    selectAll: false, // Boolean to manage 'select all' state
    from_date: moment().format(),
    to_date: moment().format(),
  };

  onDeleteClick(id) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.deleteJob(id),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  }

  componentDidMount() {
    this.props.getJobs();
    if (this.props.flash !== "") {
      this.props.alert.show(this.props.flash);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jobs) {
      this.setState({ jobs: nextProps.jobs });
    }
    if (nextProps.flash) {
      this.setState({ flash: nextProps.flash });
    }
  }
  handleDelete = async () => {
    this.setState({ downloading: true });
    let deletedRows = this.state.selectedRows;

    console.log("deletedRows>>", deletedRows);

    await this.props.hideMultipleJobs(
      deletedRows,
      this.props.history,
      this.props.alert
    );
    this.setState({ downloading: false, selectedRows: [] });
    this.props.getJobs();
  };

  // Toggle row selection
  toggleRow = (id) => {
    const { selectedRows } = this.state;
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [...selectedRows];

    if (selectedIndex === -1) {
      newSelectedRows.push(id); // Add the row if it's not selected
    } else {
      newSelectedRows.splice(selectedIndex, 1); // Remove the row if it's already selected
    }

    this.setState({ selectedRows: newSelectedRows });
  };

  // Toggle select all rows
  toggleSelectAll = () => {
    const { jobs } = this.state;
    if (this.state.selectAll) {
      // Deselect all
      this.setState({
        selectedRows: [],
        selectAll: false,
      });
    } else {
      // Select all row IDs
      const allRowIds = jobs.map((job) => job._id);
      this.setState({
        selectedRows: allRowIds,
        selectAll: true,
      });
    }
  };

  render() {
    const { loading } = this.props;

    const { jobs, selected, selectAll } = this.state;

    const columns = [
      {
        // Checkbox column for row selection
        Header: () => (
          <input
            type="checkbox"
            checked={selectAll}
            onChange={this.toggleSelectAll}
          />
        ),
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => (
          <input
            type="checkbox"
            checked={this.state.selectedRows.includes(original._id)} // Check if the row is selected
            onChange={() => this.toggleRow(original._id)}
          />
        ),
        sortable: false,
        filterable: false,
        width: 45,
      },
      {
        Header: "Title",
        id: "title",
        accessor: (d) => `${d.title} (${d.applicants.length})`,
        filterMethod: caseInsensitiveFilter, // Apply custom filter function
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            // placeholder="Search by Name"
            value={filter ? filter.value : ""}
            onChange={(e) => onChange(e.target.value)}
          />
        ),
      },
      {
        id: "Grade",
        Header: "CGPA/Percentage",
        accessor: (d) =>
          `${d.cgpa} ${d.percentage ? " / " + d.percentage + "%" : ""}`,
        filterMethod: caseInsensitiveFilter, // Apply custom filter function
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            // placeholder="Search by Name"
            value={filter ? filter.value : ""}
            onChange={(e) => onChange(e.target.value)}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        filterMethod: caseInsensitiveFilter, // Apply custom filter function
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            // placeholder="Search by Name"
            value={filter ? filter.value : ""}
            onChange={(e) => onChange(e.target.value)}
          />
        ),
      },
      {
        Header: "Experience",
        accessor: "experience",
        filterMethod: caseInsensitiveFilter, // Apply custom filter function
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            // placeholder="Search by Name"
            value={filter ? filter.value : ""}
            onChange={(e) => onChange(e.target.value)}
          />
        ),
      },
      {
        Header: "Skills",
        accessor: "skills",
        Cell: ({ value }) => {
          // Check if `value` is an array and get the first element
          const firstSkill =
            Array.isArray(value) && value.length > 0 ? value[0] : "";

          // Remove any HTML tags from the first skill
          return typeof firstSkill === "string"
            ? firstSkill.replace(/<[^>]*>/g, " ").trim()
            : firstSkill;
        },
        filterMethod: caseInsensitiveFilter, // Apply custom filter function
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            // placeholder="Search by Name"
            value={filter ? filter.value : ""}
            onChange={(e) => onChange(e.target.value)}
          />
        ),
      },
      {
        Header: () => <span>Actions</span>,
        accessor: "_id",
        Cell: (row) => (
          <div>
            <i
              className="fa fa-trash-alt"
              aria-hidden="true"
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={"/jobs/edit/" + row.value}>
              <i className="far fa-edit" title="Edit" />
              {/* <button className='btn btn-warning'>Edit</button> */}
            </Link>
            &nbsp;
            <Link to={"/jobs/show/" + row.value}>
              <i className="fas fa-eye" />
            </Link>
          </div>
        ),
      },
    ];

    // let jobsListing;
    let data;
    if (jobs == null || loading) {
      // jobsListing = <Spinner />;
    } else {
      if (jobs.length > 0) {
        // jobsListing = jobs.map(job => <Job key={job._id} job={job} />)
        data = jobs;
      } else {
        // jobsListing = <h4> No jobs found...</h4>;
      }
    }

    return (
      <div>
        <Navbar />
        <div className="profiles">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="new-job col-md-6">
                    <Link to={`/jobs/new`} className="btn btn-info">
                      New Job
                    </Link>
                  </div>
                  <div
                    className="new-job col-md-6"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      className="btn btn-info"
                      onClick={this.handleDelete}
                      disabled={this.state.selectedRows.length === 0}
                    >
                      Delete Selected Jobs
                    </button>
                  </div>
                </div>
                <h3 className="display-5 text-center">Jobs Listing</h3>
                <p className="lead text-center">Browse and post jobs</p>
                <div className="row mb-2">
                  <div className="col-md-3">
                    <label>From Date</label>
                    <br />
                    <DatePicker
                      className="form-control form-control-lg"
                      selected={moment(this.state.from_date)}
                      onChange={(e) =>
                        this.setState({
                          from_date: e,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <label>To Date</label>
                    <br />
                    <DatePicker
                      className="form-control form-control-lg"
                      selected={moment(this.state.to_date)}
                      onChange={(e) =>
                        this.setState({
                          to_date: e,
                        })
                      }
                    />
                  </div>
                  <div
                    className="col-md-3  d-flex mt-4 justify-content-end"
                    style={{ height: "100%" }}
                  >
                    <button
                      className="btn btn-warning"
                      onClick={() =>
                        this.props.getJobs(
                          moment(this.state.from_date).format("YYYY-MM-DD"),
                          moment(this.state.to_date).format("YYYY-MM-DD")
                          // this.state.from_date,
                          // this.state.to_date
                        )
                      }
                      // disabled={this.state.selectedRows.length === 0}
                    >
                      Find
                    </button>
                  </div>
                </div>
                {/* {jobsListing} */}
                <ReactTable
                  className="-highlight "
                  data={data}
                  columns={columns}
                  filterable
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Jobs.propTypes = {
  getJobs: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  jobs: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  jobs: state.jobsData.jobs,
  flash: state.jobsData.flash,
});

export default connect(mapStateToProps, {
  deleteJob,
  getJobs,
  hideMultipleJobs,
})(withAlert(Jobs));
