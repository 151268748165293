import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { loginUser, confirmEmailFlag } from "../../actions/authActions";
import { Link } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import { withAlert } from "react-alert";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/profile-step-1");
    } else {
      if (this.props.confirmEmail) {
        this.props.alert.show(
          `Email confirmation link has been sent to your email address. Please open the email to confirm.`
        );
        this.props.confirmEmailFlag(false);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.isAuthenticated &&
      nextProps.auth.user.role === "admin"
    ) {
      this.props.history.push("/dashboard");
    } else if (nextProps.auth.isAuthenticated) {
      if (nextProps.auth.user.termsAndConditions == true) {
        this.props.history.push("/careers");
      } else {
        this.props.history.push("/profile-step-1");
      }
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="loginSection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-10">
              <div className="loginLogo">
                <a href="/">
                  <img src={require("../../images/site-logo.svg")} alt="NRP" />
                </a>
              </div>
              <form onSubmit={this.onSubmit}>
                <div className="loginForm">
                  {/* <h3> Please Login to continue</h3> */}
                  <div className="form-group">
                    <input
                      type="email"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.email,
                      })}
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    {errors.email && (
                      <div className="is-invalid">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.password,
                      })}
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="is-invalid">{errors.password}</div>
                    )}
                  </div>
                  {/* <div className="form-group">
                    <img
                      src={require("../../images/google-captcha.png")}
                      alt="img"
                    />
                  </div> */}
                  <div className="form-group">
                    <input type="submit" value="Login" />
                  </div>
                </div>
              </form>
              <div className="loginFooter">
                <span> Not a registered user yet? </span>
                <p>
                  <Link to="/register">Create an account</Link> to apply for our
                  career opportunities.
                </p>
                <p>
                  <a
                    href="/forgot-password
                  "
                  >
                    Forgot your password?
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  confirmEmail: state.auth.confirmEmail,
});

export default connect(mapStateToProps, { loginUser, confirmEmailFlag })(
  withAlert(Login)
);