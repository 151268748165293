import axios from 'axios'

import {
  GET_ERRORS,
  GET_DEPARTMENT,
  GET_DEPARTMENTS,
  DELETE_DEPARTMENT,
  DEPARTMENT_LOADING
} from './types'

// Create a DEPARTMENT
export const createDepartment = (departmentsData, history) => dispatch => {
console.log("TCL: departmentsData", departmentsData)
  axios
    .post('/api/admin/departments/create', departmentsData)
    .then(res => history.push('/departments'))
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Delete Department
export const deleteDepartment = id => dispatch => {
  dispatch(setDepartmentLoading())
  axios
    .delete(`/api/admin/departments/${id}`)
    .then(res => {
      dispatch({
        type: DELETE_DEPARTMENT,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    )
}

// Get a Department
export const getDepartment = id => dispatch => {
  axios
    .get('/api/admin/departments/' + id)
    .then(res => {
      dispatch({
        type: GET_DEPARTMENT,
        payload: res.data
      })
    })
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Get all Departments Publicly
export const getPublicDepartments = () => dispatch => {
  dispatch(setDepartmentLoading())
  axios
    .get('/api/public/departments')
    .then(res =>
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    )
}

// Get all Departments
export const getDepartments = () => dispatch => {
  dispatch(setDepartmentLoading())
  axios
    .get('/api/admin/departments')
    .then(res =>
      dispatch({
        type: GET_DEPARTMENTS,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    })
}

// Update New Department
export const updateDepartment = (id, departmentData, history) => dispatch => {
  axios
    .post('/api/admin/departments/update/' + id, departmentData)
    .then(res => history.push('/departments'))
    .catch(err => {
      if (err.message === 'Request failed with status code 404') {
        window.location.href = '/'
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    })
}

// Set Loader icon untill state changes
export const setDepartmentLoading = () => {
  return {
    type: DEPARTMENT_LOADING
  }
}
