import React, { Component } from 'react'
import Navbar from '../layout/Navbar'
import Footer from './Footer'

export default class Dashboard extends Component {
  render () {
    return (
      <div>
        <Navbar />
        <div className='container'>
          <h2>Welcome to Nishat Admin panel. </h2>
          <p>Please browse the links from the header menu.</p>
        </div>
        <Footer />

      </div>
    )
  }
}
