import axios from "axios";

import {
  GET_ERRORS,
  GET_JOBS,
  DELETE_JOB,
  GET_JOB,
  JOB_LOADING,
  APPLY_FOR_JOB,
  REMOVE_APPLICANT,
  SHORTLIST_APPLICANT,
  CHANGE_JOB_STATUS,
  GET_USER_JOB,
  GET_USER_JOBS,
  SET_JOB_APPLYING,
  REMOVE_JOB_APPLYING,
  GET_FLASH,
} from "./types";

// Create New Job
export const createJob = (jobData, history, alert) => (dispatch) => {
  axios
    .post("/api/admin/jobs/create", jobData)
    .then((res) => {
      history.push("/jobs");
      alert.show("Job has been created.");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Update New Job
export const updateJob = (id, jobData, history, alert) => (dispatch) => {
  axios
    .post("/api/admin/jobs/update/" + id, jobData)
    .then((res) => {
      history.push("/jobs");
      alert.show("Job has been updated.");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// Update New Job
export const hideMultipleJobs = (deletedRows, history, alert) => (dispatch) => {
  console.log("hideMultipleJobs >> deletedRows", deletedRows);
  axios
    .post("/api/admin/jobs/hideMultipleJobs", deletedRows)
    .then((res) => {
      history.push("/jobs");
      alert.show("Job has been Removed.");
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get all jobs
export const getJobs = (fromDate, toDate) => (dispatch) => {
  dispatch(setJobLoading());
  // Dynamically build the params object
  const params = {};
  if (fromDate) params.fromDate = fromDate;
  if (toDate) params.toDate = toDate;
  axios
    .get("/api/public/jobs", { params })
    .then((res) =>
      dispatch({
        type: GET_JOBS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: null,
      })
    );
};

export const getDepartmentJobs = (department_id) => (dispatch) => {
  dispatch(setJobLoading());
  axios
    .get("/api/public/jobs/department/" + department_id)
    .then((res) => {
      dispatch({
        type: GET_JOBS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: null,
      });
    });
};

// Delete Job
export const deleteJob = (id) => (dispatch) => {
  // dispatch(setJobLoading());
  axios
    .delete(`/api/admin/jobs/${id}`)
    .then((res) => {
      dispatch({
        type: DELETE_JOB,
        payload: res.data,
      });
    })
    .catch((err) => {
      window.location.href = "/";
      dispatch({
        type: GET_ERRORS,
        payload: null,
      });
    });
};

// Get a  Job to edit/display
export const getJob = (id) => (dispatch) => {
  // dispatch(setJobLoading());
  axios
    .get(`/api/jobs/${id}`)
    .then((res) => {
      dispatch({
        type: GET_JOB,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: null,
      })
    );
};

// Get Available Jobs
export const getAvailableJobs = () => (dispatch) => {
  dispatch(setJobLoading());
  axios
    .get(`/api/jobs/available/jobs`)
    .then((res) => {
      dispatch({
        type: GET_JOBS,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: null,
      })
    );
};

// Apply for a Job
// export const applyForJob = (
//   job_id,
//   applicant_id,
//   answers,
//   history,
//   alert
// ) => dispatch => {
//   axios
//     .post(`/api/jobs/${job_id}/${applicant_id}`, answers)
//     .then(res => {
//       dispatch({
//         type: APPLY_FOR_JOB,
//         payload: res.data
//       })
//       history.push('/careers')
//       alert.show("You've successfully applied for the job")
//     })
//     .catch(err => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response
//       })
//     })
// }

export const applyForJob = (job_id, applicant_id, answers, history, alert) => (
  dispatch
) => {
  if (job_id) {
    axios
      .post(`/api/jobs/${job_id}/${applicant_id}`, answers)
      .then((res) => {
        dispatch({
          type: APPLY_FOR_JOB,
          payload: res.data,
        });
        history.push("/careers");
        alert.show("You've successfully applied for the job");
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response,
        });
      });
  } else {
    alert("Job Id Not Found Try");
  }
};

// shortlist Applicant
export const shortlistApplicant = (job_id, applicant_id) => (dispatch) => {
  axios
    .post(`/api/admin/jobs/${job_id}/${applicant_id}`)
    .then((res) => {
      dispatch({
        type: SHORTLIST_APPLICANT,
        payload: res.data,
      });
      // history.push(`jobs/show/${res.job._id}`);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const removeApplicant = (job_id, applicant_id) => (dispatch) => {
  axios
    .post(`/api/admin/jobs/remove-applicant/${job_id}/${applicant_id}`)
    .then((res) => {
      dispatch({
        type: REMOVE_APPLICANT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

// Set Loader icon untill state changes
export const setJobLoading = () => {
  return {
    type: JOB_LOADING,
  };
};

export const sendEmailInvitation = (invitationData) => (dispatch) => {
  axios.post("/api/public/send-email", invitationData).then((res) => {});
};
export const sendTestInvitation = (testInvitationData) => (dispatch) => {
  axios
    .post("/api/public/send-test-email", testInvitationData)
    .then((res) => {});
  // axios.post("/api/public/send-test-email", testInvitationData).then(res => {});
};
export const sendRejectionMail = (testRejectionData, alert) => (dispatch) => {
  axios
    .post("/api/public/send-rejection-email", testRejectionData)
    .then((res) => {
      alert.show("Mail has been Sent.");
    });
  // axios.post("/api/public/send-test-email", testInvitationData).then(res => {});
};

export const changeStatus = (job_id, job_status) => (dispatch) => {
  axios
    .post(`/api/admin/jobs/change-status/${job_id}/${job_status}`)
    .then((res) => {
      dispatch({
        type: CHANGE_JOB_STATUS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const getUserJobQA = (job_id, user_id) => (dispatch) => {
  axios
    .get(`/api/admin/jobs/user-job-qa/${job_id}/${user_id}`)
    .then((res) => {
      // Dispatch Data about User's answers of employment question
      dispatch({
        type: GET_USER_JOB,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const saveAdminRemarks = (job_id, user_id, remarks) => (dispatch) => {
  axios
    .post(`/api/admin/jobs/save-remarks/${job_id}/${user_id}`, { remarks })
    .then((res) => {
      dispatch({
        type: GET_USER_JOB,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.message === "Request failed with status code 404") {
        window.location.href = "/";
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const getUserJobs = (user_id) => (dispatch) => {
  axios
    .get(`/api/jobs/user-jobs/${user_id}`)
    .then((res) => {
      dispatch({
        type: GET_USER_JOBS,
        payload: res.data.userJobs,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    });
};

export const setJobApplying = (jobId) => (dispatch) => {
  dispatch({
    type: SET_JOB_APPLYING,
    payload: jobId,
  });
};

export const removeJobApplying = () => (dispatch) => {
  dispatch({
    type: REMOVE_JOB_APPLYING,
    payload: null,
  });
};