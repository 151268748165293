import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { registerUser } from '../../actions/authActions'
import { Link } from 'react-router-dom'

class Register extends Component {
  constructor () {
    super()
    this.state = {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      job_id: '',
      errors: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/careers')
    }
    if (this.props.history.location.search !== '') {
      this.setState({
        job_id: this.props.history.location.search.split('=')[1]
      })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/profile-step-1')
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit (e) {
    e.preventDefault()

    // Job Id is saved of the first job the user applies to if not Signed up.
    // The job_id will help us to apply user automatically after sign up confirmation

    const newUser = {
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      job_id: this.state.job_id
    }

    this.props.registerUser(newUser, this.props.history)
  }

  render () {
    const { errors } = this.state

    // if (Object.entries(this.state.errors).length !== 0) {
    //   errors = this.state.errors.data
    // } else {
    //   errors = this.state
    // }

    return (
      <div className='loginSection'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-5 col-md-10'>
              <div className='loginLogo'>
                <a href='/'>
                  <img src={require('../../images/site-logo.svg')} alt='NRP' />
                </a>
              </div>
              <form noValidate onSubmit={this.onSubmit}>
                <div className='loginForm'>
                  <div className='row'>
                    <div className='form-group col-sm-6'>
                      <input
                        type='text'
                        className={classnames('form-control form-control-lg', {
                          'is-invalid': errors.firstName
                        })}
                        placeholder='First Name'
                        name='firstName'
                        value={this.state.firstName}
                        onChange={this.onChange}
                      />
                      {errors.firstName && (
                        <div className='invalid-feedback'>
                          {errors.firstName}
                        </div>
                      )}
                    </div>
                    <div className='form-group col-sm-6'>
                      <input
                        type='text'
                        className={classnames('form-control form-control-lg', {
                          'is-invalid': errors.middleName
                        })}
                        placeholder='Middle Name'
                        name='middleName'
                        value={this.state.middleName}
                        onChange={this.onChange}
                      />
                      {errors.middleName && (
                        <div className='invalid-feedback'>
                          {errors.middleName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.lastName
                      })}
                      placeholder='Last Name'
                      name='lastName'
                      value={this.state.lastName}
                      onChange={this.onChange}
                    />
                    {errors.lastName && (
                      <div className='invalid-feedback'>{errors.lastName}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <input
                      type='email'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.email
                      })}
                      placeholder='Email Address'
                      name='email'
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    {errors.email && (
                      <div className='invalid-feedback'>{errors.email}</div>
                    )}
                    {/* <small className="form-text text-muted">
                      This site uses Gravatar so if you want a profile image,
                      use a Gravatar email
                    </small> */}
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <input
                          type='password'
                          className={classnames(
                            'form-control form-control-lg',
                            {
                              'is-invalid': errors.password
                            }
                          )}
                          placeholder='Password'
                          name='password'
                          value={this.state.password}
                          onChange={this.onChange}
                        />
                        {errors.password && (
                          <div className='invalid-feedback'>
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <input
                          type='password'
                          className={classnames(
                            'form-control form-control-lg',
                            {
                              'is-invalid': errors.password2
                            }
                          )}
                          placeholder='Confirm Password'
                          name='password2'
                          value={this.state.password2}
                          onChange={this.onChange}
                        />
                        {errors.password2 && (
                          <div className='invalid-feedback'>
                            {errors.password2}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='form-group'>
                    <input type='submit' value='Create Account' />
                  </div>
                </div>
              </form>
              <div className='loginFooter'>
                <p>
                  Already a registered user? Please
                  <Link to='/login'> sign in </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors.data
})

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register))
