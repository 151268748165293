import axios from "axios";

import {
  GET_ERRORS,
  GET_EDUCATION,
  GET_EDUCATIONS,
  DELETE_EDUCATION,
  EDUCATION_LOADING
} from "./types";

// Create an Education
export const createEducation = (educationsData, history) => dispatch => {
  axios
    .post("/api/admin/educations/create", educationsData)
    .then(res => history.push("/educations"))
    .catch(err => {
      if (err.message === "Request failed with status code 404") {
        window.location.href = "/";
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get all Educations
export const getEducations = () => dispatch => {
  dispatch(setEducationLoading());
  axios
    .get("/api/admin/educations")
    .then(res =>
      dispatch({
        type: GET_EDUCATIONS,
        payload: res.data
      })
    )
    .catch(err => {
      if (err.message === "Request failed with status code 404") {
        window.location.href = "/";
      }
      dispatch({
        type: GET_ERRORS,
        payload: null
      });
    });
};

// Get all Educations
export const getAllEducations = () => dispatch => {
  dispatch(setEducationLoading());
  axios
    .get("/api/public/educations")
    .then(res =>
      dispatch({
        type: GET_EDUCATIONS,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: null
      });
    });
};

// Set Loader icon untill state changes
export const setEducationLoading = () => {
  return {
    type: EDUCATION_LOADING
  };
};

export const getEducation = educationId => dispatch => {
  dispatch(setEducationLoading());
  axios
    .get(`/api/admin/educations/${educationId}`)
    .then(res => {
      dispatch({
        type: GET_EDUCATION,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
// Update Education
export const updateEducation = (
  educationId,
  educationData,
  history
) => dispatch => {
  axios
    .post("/api/admin/educations/update/" + educationId, educationData)
    .then(res => history.push("/educations"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const deleteEducation = educationId => dispatch => {
  dispatch(setEducationLoading());
  axios
    .delete(`/api/admin/educations/${educationId}`)
    .then(res => {
      dispatch({
        type: DELETE_EDUCATION,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
