import React, { Component } from 'react'
import Spinner from '../common/Spinner'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import Job from './Job'
import { getEducations, deleteEducation } from '../../actions/educationActions'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import Navbar from '../layout/Navbar'

class Educations extends Component {
  // following is ES6 syntax to initialze constructor
  state = {
    educations: null
  }

  onDeleteClick (id) {
    this.props.deleteEducation(id)
  }

  componentDidMount () {
    this.props.getEducations()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.educations) {
      this.setState({ educations: nextProps.educations })
    }
  }

  render () {
    const { loading } = this.props

    const { educations } = this.state

    const columns = [
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: () => <span>Actions</span>,
        accessor: '_id',
        Cell: row => (
          <div>
            <i
              className='fa fa-trash-alt'
              aria-hidden='true'
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={'/educations/edit/' + row.value}>
              <i className='far fa-edit' title='Edit' />
            </Link>
          </div>
        )
      }
    ]
    let educationsListing
    let data
    if (educations == null || loading) {
      educationsListing = <Spinner />
    } else {
      if (educations.length > 0) {
        data = educations
      } else {
        educationsListing = <h4> No educations found...</h4>
      }
    }

    return (
      <div>
        <Navbar />
        <div className='profiles'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='new-job'>
                  <Link to={`/educations/new`} className='btn btn-info'>
                    Create
                  </Link>
                </div>
                <h3 className='display-5 text-center'>Degree Listing</h3>
                <p className='lead text-center'>Browse Degrees</p>
                <ReactTable data={data} columns={columns} filterable />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Educations.propTypes = {
  getEducations: PropTypes.func.isRequired,
  educations: PropTypes.array.isRequired,
  deleteEducation: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  educations: state.educationsData.educations
})

export default connect(
  mapStateToProps,
  {
    getEducations,
    deleteEducation
  }
)(Educations)
