import {
  GET_DEPARTMENTS,
  GET_DEPARTMENT,
  DELETE_DEPARTMENT
} from "../actions/types";

const initialState = {
  department: {},
  departments: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
        loading: false
      };
    case GET_DEPARTMENT:
      return {
        ...state,
        department: action.payload,
        loading: false
      };
    case DELETE_DEPARTMENT:
      return {
        ...state,
        // remove deleted department from state to update page
        departments: state.departments.filter(
          department => department._id !== action.payload.id
        ),
        loading: false
      };

    default:
      return state;
  }
}
