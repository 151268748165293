import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Spinner from '../common/Spinner'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { testTaken } from '../../actions/userJobActions'
import { createUserTest, updateTime } from '../../actions/userTestActions'
import { getTest } from '../../actions/userTestActions'
import { connect } from 'react-redux'
import Navbar from '../layout/Navbar'
import _ from 'lodash'
import StepZilla from 'react-stepzilla'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'

class CreateUserTest extends Component {
  constructor () {
    super()
    this.state = {
      test: null,
      test_id: '',
      user: '',
      _form: null,
      questions: [],
      count: 0
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount () {
    if (this.props.match.params.test_id) {
      this.props.getTest(this.props.match.params.test_id)
    }
    this.props.testTaken(
      this.props.auth.user.id,
      this.props.match.params.job_id,
      true
    )
    // this.attachNode(this.state._form)
    // this.startTimer()
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.test) {
      this.setState({ test: nextProps.test })
      this.setState({ count: nextProps.test.time })
    }
  }

  attachNode (node) {
    this.state._form = ReactDOM.findDOMNode(node)
  }

  stopTimer () {
    // clearInterval(this.timer)
    if (this.state.count === 0 && this.state._form !== null) {
      // this.state._form.onSubmit()
      const { test } = this.state
      let questionsData = _.uniqWith(this.state.questions, _.isEqual)
      questionsData = _.compact(questionsData)
      let newUserTest = {
        test: test._id,
        questions: questionsData,
        user: this.props.auth.user.id
      }
      let q = this.props.userTestsData.concat(questionsData)
      this.props.createUserTest(
        newUserTest,
        this.props.formEnd,
        q,
        this.props.history
      )
    }
  }

  tick () {
    this.setState({ count: this.state.count - 1 })
    this.stopTimer()
  }

  startTimer () {
    this.timer = setInterval(this.tick.bind(this), 60000)
  }

  onSubmit (e) {
    e.preventDefault()
    const { test } = this.state
    let questionsData = _.uniqWith(this.state.questions, _.isEqual)
    questionsData = _.compact(questionsData)
    let newUserTest = {
      test: test._id,
      questions: questionsData,
      user: this.props.auth.user.id
    }
    let q = this.props.userTestsData.concat(questionsData)
    this.props.createUserTest(
      newUserTest,
      this.props.formEnd,
      q,
      this.props.history
    )
  }

  onFormSubmit (step) {
    const { test } = this.state
    let questionsData = _.uniqWith(this.state.questions, _.isEqual)
    questionsData = _.compact(questionsData)
    let newUserTest = {
      test: test._id,
      questions: questionsData,
      user: this.props.auth.user.id
    }
    let q = this.props.userTestsData.concat(questionsData)
    this.props.createUserTest(
      newUserTest,
      this.props.formEnd,
      q,
      this.props.history
    )
  }

  handleChange = (e, questionId, refId) => {
    const questions = [...this.state.questions]
    let question = questions.find(q => q.question_id === refId)
    if (question) {
      question.answer = e.target.value
      questions.push(question)
    } else {
      questions.push({ id: refId, answer: e.target.value })
    }
    this.setState({
      questions
    })
  }

  render () {
    // New implementation
    //debugger
    const { test, loading } = this.state
    let testItem
    if (test === null || loading) {
      testItem = <Spinner />
    } else {
      let questionsCount = test.totalQuestions

      let testQuestions = test.questions
      let aptCount = questionsCount * (test.aptitudePercentage / 100)
      let techCount = questionsCount * (test.technicalPercentage / 100)
      let aptitude = _.filter(testQuestions, [
        'typeOfQuestion',
        'aptitude'
      ]).slice(0, aptCount)
      let technical = _.filter(testQuestions, [
        'typeOfQuestion',
        'technical'
      ]).slice(0, techCount)
      let aptArray = aptitude
      let finalQuestions = aptArray.concat(technical)
      let stepsCount = Math.ceil(questionsCount / 10)
      console.log("steps_count", stepsCount);
      const steps = []
      let endForm
      let CustomTag = ''
      let questionsForStep
      for (let i = 0; i <= stepsCount - 1; i++) {
        switch (i) {
          case 0: {
            CustomTag = Step1
            questionsForStep = _.shuffle(finalQuestions.slice(0, 10))
            i + 1 === stepsCount ? (endForm = true) : (endForm = false)
            console.log("case0", endForm)
            break
          }
          case 1: {
            CustomTag = Step2
            questionsForStep = _.shuffle(finalQuestions.slice(10, 20))
            i + 1 === stepsCount ? (endForm = true) : (endForm = false)
            console.log("case1", endForm)
            break
          }
          case 2: {
            CustomTag = Step3
            questionsForStep = _.shuffle(finalQuestions.slice(20, 30))
            i + 1 === stepsCount ? (endForm = true) : (endForm = false)
            console.log("case2", endForm)
            break
          }
          case 3: {
            CustomTag = Step4
            questionsForStep = _.shuffle(finalQuestions.slice(30, 40))
            i + 1 === stepsCount ? (endForm = true) : (endForm = false)
            break
          }
          case 4: {
            CustomTag = Step5
            questionsForStep = _.shuffle(finalQuestions.slice(40, 50))
            i + 1 === stepsCount ? (endForm = true) : (endForm = false)
            break
          }
          case 5: {
            CustomTag = Step6
            questionsForStep = _.shuffle(test.questions.slice(50, 60))
            i + 1 === stepsCount ? (endForm = true) : (endForm = false)
            break
          }
          default: {
            break
          }
        }
        steps.push({
          name: 'Step' + (i + 1),
          component: (
            <CustomTag
              questions={questionsForStep}
              formEnd={endForm}
              test={test._id}
            />
          )
        })
      }

      return (
        <div>
          <div className='innerPageContentWrap'>
            <div
              className='innerPageContentContainer container'
              style={{ marginBottom: -30 }}
            >
              <div className='step-progress'>
                <StepZilla
                  steps={steps}
                  preventEnterSubmission
                  dontValidate
                  nextButtonText='Proceed to Next Step'
                  showSteps={false}
                  preventEnterSubmission
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null

    // Below is one page implementation of test
  }
}

CreateUserTest.propTypes = {
  getTest: PropTypes.func.isRequired,
  test: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  test: state.testsData.test,
  auth: state.auth,
  userTestsData: state.userTestsData.questions
})

export default connect(
  mapStateToProps,
  { getTest, createUserTest, testTaken }
)(withRouter(CreateUserTest))
