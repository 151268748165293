import React from 'react'
import { Link } from 'react-router-dom'

export default function ProfileFooter () {
  return (
    <div className='loginFooter'>
      <p>
        <a href='/'>Home</a>
      </p>
    </div>
  )
}
