import React, { Component } from 'react'
import Navbar from '../layout/Navbar'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from '../../validation/is-empty'
import { getUserJobs } from '../../actions/jobActions'

class UserTest extends Component {
  componentDidMount () {
    if (this.props.auth.user.role == 'user') {
      this.props.getUserJobs(this.props.auth.user.id)
    }
  }

  render () {
    const { userJobs } = this.props
    const test_id = this.props.match.params.test_id
    const job_id = this.props.match.params.job_id
    return (
      <div>
        <Navbar />
        <div className='innerPageContentWrap'>
          <div className='innerPageContentContainer container'>
            <div className='row'>
              <div className='col-lg-3 col-md-4' />
              <div className='col-lg-9 col-md-8' />
              <div className='container mt-5 jobListSecTop'>
                <h2>
                  Please read the following instructions carefully. In case of
                  any query please feel free to ask our HR team member before
                  commencement of the Test.
                </h2>
                <h2>Instructions:</h2>
                <div className='container'>
                  <ul style={{ 'list-style-type': 'disc' }}>
                    <li>
                      The Test link is a one-time link, please click on it only
                      when you are told to do so
                    </li>
                    <li>
                      Total number of questions and time will be displayed on
                      the top right corner of your screen
                    </li>
                    <li>
                      All questions are MCQ based, please click the correct
                      option
                    </li>

                    <li>
                      In case you do not attempt a question, system will mark it
                      as zero; there is no negative marking
                    </li>

                    <li>
                      Please ensure you attempt all questions in one go, you
                      cannot go back
                    </li>

                    <li>
                      if you are found googling and cheating or taking a screenshot 
                      you will be immediately disqualified{' '}
                    </li>

                    <li> Use of onscreen calculators is allowed</li>

                    <li>
                      In case time runs out, your test will automatically be
                      submitted{' '}
                    </li>
                  </ul>
                </div>
                {/* {userJobs && (
                  <Link to={this.props.userJobs[0].testLink || "/"}>
                    Start Test
                  </Link>
                )} */}
                {userJobs &&
                  userJobs.map(uj => {
                    if (
                      uj.testLink &&
                      uj.testLink.includes(test_id) &&
                      uj.job &&
                      uj.job._id == job_id
                    ) {
                      return (
                        <div>
                          <p>
                            <b>Test for:</b> {uj.job.title} position
                          </p>
                          <Link to={uj.testLink || '/'}>Start Test</Link>
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Navbar.propTypes = {
  userJobs: PropTypes.object,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  userJobs: state.jobsData.userJobs,
  auth: state.auth
})

export default connect(
  mapStateToProps,
  { getUserJobs }
)(UserTest)
