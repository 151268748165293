import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { forgotPassword } from '../../actions/authActions'
import { connect } from 'react-redux'
import { withAlert } from 'react-alert'

class ForgotPassword extends Component {
  constructor () {
    super()
    this.state = {
      email: '',
      errors: {}
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (e) {
    e.preventDefault()

    const forgotPass = {
      email: this.state.email
    }
    this.props.forgotPassword(forgotPass, this.props.history, this.props.alert)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors })
    }
  }

  onChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render () {
    const { errors } = this.state
    debugger
    return (
      <div>
        <div className='loginSection'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-5 col-md-10'>
                <div className='loginLogo'>
                  <a href='/'>
                    <img
                      src={require('../../images/site-logo.svg')}
                      alt='NRP'
                    />
                  </a>
                </div>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className='form-group'>
                    <input
                      type='email'
                      className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.email
                      })}
                      placeholder='Enter your email'
                      name='email'
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    {errors.email && (
                      <div className='invalid-feedback'>{errors.email}</div>
                    )}
                  </div>
                  <button type='submit' className='btn btn-info btn-block mt-4'>
                    Send password reset Instructions
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  errors: state.errors.data
})

export default connect(
  mapStateToProps,
  { forgotPassword }
)(withAlert(ForgotPassword))
