import React, { Component } from 'react'
import ProfileHeader from './ProfileHeader'
import SiteLogo from '../common/SiteLogo'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProfileFooter from './ProfileFooter'
import isEmpty from '../../validation/is-empty'
import { createProfileStepOne, getUser } from '../../actions/authActions'

class ProfileStepOne extends Component {
  constructor () {
    super()
    this.state = {
      firstName: '',
      lastName: '',
      avatar: '',
      middleName: '',
      email: '',
      city: '',
      address: '',
      phoneNumber: '',
      resume: '',
      resumeFileName: '',
      imagePreviewUrl: '',
      errors: {}
    }
    this.avatarPreview = React.createRef()
    this.avatarImage = React.createRef()
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount () {
    if (this.props.user.id) {
      this.props.getUser(this.props.user.id)
    }
  }

  componentWillReceiveProps (nextProps) {
    if (!isEmpty(nextProps.errors)) {
      this.setState({ errors: nextProps.errors })
    } else {
      this.setState({
        firstName: nextProps.profile.firstName,
        middleName: nextProps.profile.middleName,
        lastName: nextProps.profile.lastName,
        email: nextProps.profile.email,
        phoneNumber: nextProps.profile.phoneNumber,
        city: nextProps.profile.city,
        avatar: nextProps.profile.avatar,
        resume: nextProps.profile.resume,
        resumeFileName: nextProps.profile.resumeFileName,
        address: nextProps.profile.address
      })
    }
  }

  onSubmit (e) {
    e.preventDefault()
    if (this.state.resume == null || this.state.resume == undefined) {
      alert('Please upload the Resume before proceeding.')
    } else if (this.state.resume.size > 3145728) {
      debugger
      alert('Size of the Resume must be less than 3 MB')
    } else if (this.state.avatar && this.state.avatar.size > 1572864) {
      alert('Size of the Picture must be less than 1.5 MB')
    } else {
      const ProfileStepOne = new FormData()
      ProfileStepOne.append('firstName', this.state.firstName)
      ProfileStepOne.append('middleName', this.state.middleName)
      ProfileStepOne.append('lastName', this.state.lastName)
      ProfileStepOne.append('city', this.state.city)
      ProfileStepOne.append('address', this.state.address)
      ProfileStepOne.append('phoneNumber', this.state.phoneNumber)
      ProfileStepOne.append('email', this.state.email)
      ProfileStepOne.append('resume', this.state.resume)
      ProfileStepOne.append('avatar', this.state.avatar)
      this.props.createProfileStepOne(ProfileStepOne, this.props.history)
    }
  }
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        avatar: file,
        imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }
  onChange (e) {
    switch (e.target.name) {
      case 'resume':
        this.setState({ resume: e.target.files[0] })
        break
      default:
        this.setState({ [e.target.name]: e.target.value })
    }
  }

  removePicture (e) {
    this.setState({ avatar: '', imagePreviewUrl: '' })
  }

  render () {
    const { errors } = this.state

    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl || this.state.avatar) {
      let $imageUrl = this.state.avatar;
      if(imagePreviewUrl)
        $imageUrl = imagePreviewUrl;
      $imagePreview = (<img src={$imageUrl} />);
    } else {
      $imagePreview = (
        <img
          src={require('../../images/default.png')}
          height='200'
          ref={ref => (this.avatarPreview = ref)}
          alt='Image preview...'
        />
      )
    }

    debugger
    return (
      <div>
        <ProfileHeader tab={1} />
        <div className='signupStepSection signupbgTwo'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-md-10'>
                {/* <SiteLogo /> */}

                <p className='stepsOverview'>
                  Please take a few moments to register. You will need this
                  information to access your account in the future.
                </p>
                <form
                  noValidate
                  onSubmit={this.onSubmit}
                  encType='multipart/form-data'
                >
                  <div className='loginForm'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='profilePicInput'>
                          <strong>
                            {$imagePreview}
                            {/* {this.state.avatar && this.state.avatar.path ? (
                              <img src={this.state.avatar.path} alt='img' />
                            ) : (
                              <img
                                src={require('../../images/default.png')}
                                height='200'
                                ref={ref => (this.avatarPreview = ref)}
                                alt='Image preview...'
                              />
                            )} */}
                            {/* <span>
                              <i className='fas fa-plus' />
                            </span>
                            <p> Upload Picture</p> */}
                          </strong>
                          <input
                            className='form-control'
                            type='file'
                            name='avatar'
                            ref={ref => (this.avatarImage = ref)}
                            onChange={(e)=>this._handleImageChange(e)}
                          />
                        </div>
                        <div className='profilePicDetails'>
                          <p>
                            Maximum Size: 1.5 MB <br /> Allowed Types : jpg, png
                          </p>
                          <a onClick={this.removePicture.bind(this)}>
                            <i className='far fa-trash-alt' /> Remove Picture
                          </a>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            name='firstName'
                            value={this.state.firstName}
                            onChange={this.onChange}
                            placeholder='* First Name'
                          />
                          {errors.firstName && (
                            <div className='is-invalid'>{errors.firstName}</div>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            name='middleName'
                            value={this.state.middleName}
                            onChange={this.onChange}
                            placeholder='Middle Name'
                          />
                          {errors.middleName && (
                            <div className='is-invalid'>
                              {errors.middleName}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            name='lastName'
                            onChange={this.onChange}
                            value={this.state.lastName}
                            placeholder='* Last Name'
                          />
                          {errors.lastName && (
                            <div className='is-invalid'>{errors.lastName}</div>
                          )}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            name='address'
                            value={this.state.address}
                            onChange={this.onChange}
                            placeholder='* Address'
                          />
                          {errors.address && (
                            <div className='is-invalid'>{errors.address}</div>
                          )}
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='text'
                            name='city'
                            value={this.state.city}
                            onChange={this.onChange}
                            placeholder='* City'
                          />
                          {errors.city && (
                            <div className='is-invalid'>{errors.city}</div>
                          )}
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='tel'
                            name='phoneNumber'
                            onChange={this.onChange}
                            value={this.state.phoneNumber}
                            placeholder='* Phone Number'
                            data-toggle='tooltip'
                            title=' Hint: 11 digits without spaces (E.g., 03001234567)'
                            maxLength='11'
                          />
                          {errors.phoneNumber && (
                            <div className='is-invalid'>
                              {errors.phoneNumber}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <input
                            className='form-control'
                            type='email'
                            name='email'
                            value={this.state.email}
                            placeholder='Email'
                            readOnly
                          />
                        </div>
                      </div>

                      <div className='col-sm-12'>
                        <div className='form-group uploadCvfield'>
                          <label>Upload your recent resume</label>
                          <br />
                          <input
                            type='file'
                            name='resume'
                            onChange={this.onChange}
                          />
                          <h6>
                            {this.state.resume && this.state.resumeFileName
                              ? this.state.resumeFileName + ' Uploaded before'
                              : ''}
                          </h6>
                          <span>
                            Maximum Size: 3.0 MB. Allowed Types : pdf, docx, doc
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='form-group'>
                      <input type='submit' value='Next' />
                    </div>
                  </div>
                </form>
                <ProfileFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProfileStepOne.propTypes = {
  createProfileStepOne: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.auth.user,
  profile: state.auth.profile,
  errors: state.errors
})

export default connect(
  mapStateToProps,
  { createProfileStepOne, getUser }
)(ProfileStepOne)
