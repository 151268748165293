import React, { Component } from "react";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Job from './Job'
import {
  getEmploymentQuestions,
  deleteEmploymentQuestion
} from "../../actions/employmentQuestionsActions";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Navbar from "../layout/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class EmploymentQuestions extends Component {
  // follwoeing is ES6 syntax to initialze constructor
  state = {
    employmentQuestions: null
  };

  onDeleteClick(id) {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.deleteEmploymentQuestion(id)
        },
        {
          label: "No",
          onClick: () => ""
        }
      ]
    });
  }

  componentDidMount() {
    this.props.getEmploymentQuestions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employmentQuestions) {
      this.setState({ employmentQuestions: nextProps.employmentQuestions });
    }
  }

  render() {
    const { loading } = this.props;

    const { employmentQuestions } = this.state;

    const columns = [
      {
        Header: "Title",
        accessor: "title"
      },
      {
        Header: "Limit",
        accessor: "limit"
      },
      {
        Header: () => <span>Actions</span>,
        accessor: "_id",
        Cell: row => (
          <div>
            <i
              className="fa fa-trash-alt"
              aria-hidden="true"
              onClick={this.onDeleteClick.bind(this, row.value)}
            />
            &nbsp;
            <Link to={"/employment_questions/edit/" + row.value}>
              <i className="far fa-edit" title="Edit" />
              {/* <button className='btn btn-warning'>Edit</button> */}
            </Link>
          </div>
        )
      }
    ];

    let employmentQuestionsListing;
    let data;
    if (employmentQuestions == null || loading) {
      employmentQuestionsListing = <Spinner />;
    } else {
      if (employmentQuestions.length > 0) {
        data = employmentQuestions;
      } else {
        employmentQuestionsListing = <h4> No employment Questions found...</h4>;
      }
    }

    return (
      <div>
        <Navbar />
        <div className="profiles">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="new-job">
                  <Link
                    to={`/employment_questions/new`}
                    className="btn btn-info"
                  >
                    Create an Employment Question
                  </Link>
                </div>
                <h3 className="display-5 text-center">
                  Employment Questions Listing
                </h3>
                <p className="lead text-center">Browse Employment Questions</p>
                <ReactTable data={data} columns={columns} filterable />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmploymentQuestions.propTypes = {
  getEmploymentQuestions: PropTypes.func.isRequired,
  deleteEmploymentQuestion: PropTypes.func.isRequired,
  employmentQuestions: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  employmentQuestions: state.employmentQuestionsData.employmentQuestions
});

export default connect(
  mapStateToProps,
  {
    getEmploymentQuestions,
    deleteEmploymentQuestion
  }
)(EmploymentQuestions);
