import { Field, FieldArray, reduxForm } from 'redux-form'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import isEmpty from '../../validation/is-empty'

const renderField = ({
  input,
  placeholder,
  type,
  id,
  className,
  meta: { touched, error }
}) => (
  <div>
    <input
      {...input}
      type={type}
      id={id}
      placeholder={placeholder}
      className={className}
    />
    {touched && error && <span>{error}</span>}
  </div>
)

const renderCareer = ({
  fields,
  errors,
  range,
  meta: { error, submitFailed }
}) => (
  <div>
    {submitFailed && error && <span>{error}</span>}
    <ul>
      {fields.map((careers, index) => (
        <li key={index}>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <Field
                  name={`${careers}.company`}
                  className='form-control'
                  type='text'
                  placeholder='* Company'
                  component={renderField}
                />
                {errors && errors[index] && (
                  <div className='is-invalid'>{errors[index].company}</div>
                )}
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form-group'>
                <Field
                  name={`${careers}.position`}
                  className='form-control'
                  type='text'
                  placeholder='* Position'
                  component={renderField}
                />
                {errors && errors[index] && (
                  <div className='is-invalid'>{errors[index].position}</div>
                )}
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='input-group form-group'>
                <div className='input-group-prepend'>
                  <label className='input-group-text'>From</label>
                </div>
                <Field
                  name={`${careers}.from`}
                  className='custom-select'
                  type='select'
                  component='select'
                >
                  {range &&
                    range.map((year, index) => {
                      return <option key={index + 1}>{year}</option>
                    })}
                </Field>
                {errors && errors[index] && (
                  <div className='is-invalid'>{errors[index].from}</div>
                )}
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='input-group form-group'>
                <div className='input-group-prepend'>
                  <label className='input-group-text'>To</label>
                </div>
                <Field
                  name={`${careers}.to`}
                  className='custom-select'
                  type='select'
                  component='select'
                >
                  {range &&
                    range.map((year, index) => {
                      return <option key={index + 1}>{year}</option>
                    })}
                </Field>
                {errors && errors[index] && (
                  <div className='is-invalid'>{errors[index].to}</div>
                )}
              </div>
            </div>
            <div className='col-sm-12'>
              <div className='form-group'>
                <Field
                  name={`${careers}.description`}
                  className='form-control'
                  type='text'
                  placeholder='* Description'
                  component='textarea'
                  maxlength='140'
                  data-toggle='tooltip'
                  title='140 character max.'
                />
                {errors && errors[index] && (
                  <div className='is-invalid'>{errors[index].description}</div>
                )}
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='input-group form-group'>
                <div className='input-group-prepend'>
                  <Field
                    name={`${careers}.currentJob`}
                    id={index + 'current'}
                    type='checkbox'
                    component={renderField}
                  />
                  &nbsp;
                  <label for={index + 'current'} className='currentJob'>
                    Current Job
                  </label>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="form-group">
                <label className="form-check-label">
                  <Field

                  />
                  Current Job

                </label>
              </div>
            </div> */}
            <div className='addMoreFieldGroup col-sm-12'>
              {index !== 0 && (
                <button
                  onClick={() => fields.remove(index)}
                  style={{ float: 'right' }}
                >
                  <i className='far fa-trash-alt' />
                </button>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
    <div className='addMoreFieldGroup'>
      <button
        type='button'
        onClick={() => {
          fields.push({})
        }}
      >
        <i className='fas fa-plus' /> Add Career
      </button>
    </div>
  </div>
)
class AddCareer extends Component {
  componentDidMount () {}
  componentWillReceiveProps (nextProps) {
    this.setState({ initialValues: nextProps.initialValues })
  }

  render () {
    const { errors, range } = this.props
    return (
      <div>
        <FieldArray
          name='career'
          errors={errors}
          range={range}
          component={renderCareer}
        />
      </div>
    )
  }
}

AddCareer = reduxForm({
  form: 'AddCareer',
  enableReinitialize: true
})(AddCareer)

AddCareer.propTypes = {
  initialValues: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  initialValues: !isEmpty(state.auth.profile.career)
    ? state.auth.profile
    : { career: [{}] }
})

export default connect(mapStateToProps)(AddCareer)
