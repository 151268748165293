import React from 'react'
import { Link } from 'react-router-dom'

export default function NavLinks () {
  return (
    <ul>
      {/* <li>
        <a href='http://www.nishat.net/'>Home</a>
      </li> */}
      <li>
        <a href='http://www.nishat.net/about-us'> About Us</a>
      </li>
      <li>
        <a href='http://www.nishat.net/businesses'>Businesses</a>
      </li>
      <li>
        <a href='http://www.nishat.net/corporate-governance'>Corporate Governance</a>
      </li>
      <li>
        <a href='http://www.nishat.net/investor-relations'>Investor Relations </a>
      </li>
      <li>
        <a href='http://www.nishat.net/csr'>CSR </a>
      </li>
      <li>
        <Link to='/'>Careers</Link>
      </li>
      <li>
        <a href='http://www.nishat.net/contact-us'>Contact </a>
      </li>
      {/* <li>{isAuthenticated && authLinks}</li> */}
      {/* <li>{!isAuthenticated && guestLinks}</li> */}
    </ul>
  )
}
