const validateStepOne = user => {
  return (
    user.firstName &&
    user.lastName &&
    user.middleName &&
    user.company &&
    user.phoneNumber &&
    user.email
  );
};

const validateStepTwo = user => {
  return (
    validateStepOne(user) &&
    user.education.length != 0 &&
    user.career.length != 0
  );
};

const validateStepThree = user => {
  return (
    validateStepOne(user) &&
    validateStepTwo(user) &&
    user.employmentQuestions.length != 0
  );
};

const validateStepFour = user => {
  return (
    validateStepOne(user) &&
    validateStepTwo(user) &&
    validateStepThree(user) &&
    user.termsAndConditions == true
  );
};

const isValid = (user, step) => {
  switch (step) {
    case "2":
      return validateStepOne(user);
    case "3":
      return validateStepTwo(user);
    case "4":
      return validateStepThree(user);
    case "5":
      return validateStepFour(user);
    default:
      alert("somthing went wrong");
  }
};

export default isValid;
