import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { createEmploymentQuestion } from "../../actions/employmentQuestionsActions";
import { connect } from "react-redux";
import Navbar from "../layout/Navbar";

class CreateEmploymentQuestion extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      limit: "",
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    const newEmploymentQuestion = {
      title: this.state.title,
      limit: this.state.limit
    };
    this.props.createEmploymentQuestion(
      newEmploymentQuestion,
      this.props.history
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div>
        <Navbar />
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">
                  Add Employment Question
                </h1>
                <p className="lead text-center">
                  Create an Employment Quesiton for the applicants.
                </p>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.title
                      })}
                      placeholder="Title"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className="invalid-feedback">{errors.title}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Character Limit for the answer</label>
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.limit
                      })}
                      placeholder="Character Limit for the answer"
                      name="limit"
                      value={this.state.limit}
                      onChange={this.onChange}
                    />
                    {errors.limit && (
                      <div className="invalid-feedback">{errors.limit}</div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-info btn-block mt-4">
                    Post
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CreateEmploymentQuestion.propTypes = {
  createEmploymentQuestion: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createEmploymentQuestion }
)(withRouter(CreateEmploymentQuestion));
